import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import {NEW_PET_STEP_ONE_PATH} from "../../../../../pages/pets/newpet/helpers/petFormConstants";
import PetIcon from "./PetIcon";
import {useNavigate} from "react-router-dom";

/**
 * Элемент верхнего меню - кнопка "добавить нового питомца"
 */
const TopMenuNewPet = () => {
    const navigate = useNavigate();

    return (
        <PetIcon>
            <FontAwesomeIcon icon={faCirclePlus} className="circle__plus"
                             onClick={() => navigate(NEW_PET_STEP_ONE_PATH)}/>
        </PetIcon>
    );
};

export default TopMenuNewPet;