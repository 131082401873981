import React, {useState} from 'react';
import ResetPasswordForm from "./form/ResetPasswordForm";
import ResetPasswordSuccessMessage from "./ResetPasswordSuccessMessage";

/**
 * Форма со сбросом пароля + сообщение об успешном сбросе
 */
const DoReset = ({resetCode}) => {
    const [successReset, setSuccessReset] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    return (
        <>
            {successReset ? <ResetPasswordSuccessMessage successMessage={successMsg}/> :
                <ResetPasswordForm resetCode={resetCode}
                                   setSuccessReset={setSuccessReset}
                                   setSuccessMsg={setSuccessMsg}/>

            }
        </>
    );
};

export default DoReset;