import React from 'react';
import {useSelector} from "react-redux";
import MobileIndexPage from "./MobileIndexPage";
import DesktopIndexPage from "./DesktopIndexPage";

/**
 * Главная страница
 */
const Index = () => {
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    if (isMobile) {
        return <MobileIndexPage/>;
    } else {
        return <DesktopIndexPage/>;
    }
}
export default Index;