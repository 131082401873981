import axios from "axios";
import {LOCAL} from "../app/const/main";
import {logoutUser, setUserToken} from "../app/store/userReducer";

const setupInterceptors = (store) => {
    const {dispatch} = store;
    /*axios.interceptors.request.use(
        (config) => {
            const rt = store.getState().userReducer.token;
            if (token) {
                config.headers["Authorization"] = 'Bearer_' + token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );*/


    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            //условие для логаута
            if (err.response.status === 400 && err.response?.data?.data === 'UNAUTHORIZED') {
                dispatch(logoutUser());
                window.location.href = '/';
            }

            if (err.response.status === 403) {
                try {
                    const rt = store.getState().userReducer.refreshToken;
                    const userId = store.getState().userReducer.id;
                    if (rt === '') {
                        dispatch(logoutUser());
                        window.location.href = '/';
                    }

                    const rs = await axios.post(`${LOCAL}/auth/refresh-token?userId=${userId}&refreshToken=${rt}`);

                    dispatch(setUserToken(rs.headers.authorization));
                    originalConfig.headers["Authorization"] = 'Bearer_' + rs.headers.authorization;

                    return axios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setupInterceptors;