import React from 'react';
import PanelContent from "../../../components/adminpanel/PanelContent";

/**
 * Сводка по пользователям
 */
const UsersPanel = () => {
    return (
        <PanelContent title='Пользователи'>
            Табличка с пользователями
        </PanelContent>
    );
};

export default UsersPanel;