import React, {useState} from 'react';
import BreedService from "../api/BreedService";
import FormError from "../../../components/common/form/parts/inputs/wrappers/FormError";
import Spinner from "../../../components/common/Spinner";
import OrangeButtonBlank from "../../../components/common/ui/buttons/OrangeButtonBlank";
import {hideModal, showModal} from "../../../app/store/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AutoCloseableModalWithTitle from "../../../components/common/ui/modal/AutoCloseableModalWithTitle";
import LightBrownButtonBlank from "../../../components/common/ui/buttons/LightBrownButtonBlank";
import ButtonGroup from "../../../components/common/ui/buttons/ButtonGroup";

/**
 * Зарпашивает подтверждение удаления данных о породе животного
 * в модальном окне
 */
const DeleteBreedPopupBody = ({breedId, title}) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.userReducer.token);
    const [message, setMessage] = useState();
    const [error, setError] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    function showSuccessMessage(text) {
        dispatch(showModal({
            popup: {
                title: "",
                body: <AutoCloseableModalWithTitle text={text} delay={1000}/>
            }
        }));
    }

    function deleteBreed(breedId) {
        setError(false)
        setIsLoading(true);
        const resp = BreedService.remove(breedId, token);
        resp.then((resp) => {
            setError(false)
            setIsLoading(false);
            showSuccessMessage(resp.data.data)
        }, (err) => {
            setError(true)
            setMessage(err.response?.data?.messages ?
                err.response?.data?.messages?.ERROR[0] : err.message)
        })
        setIsLoading(false)
    }

    return (
        <div>
            <span>Вы действительно хотите удалить сведения о породе {title} ?</span>
            {error ? <FormError title={message}/> : null}
            {loading ? <Spinner/>
                : <ButtonGroup>
                    <OrangeButtonBlank onClick={() => dispatch(hideModal())}>
                        {t("button.close")}
                    </OrangeButtonBlank>
                    <LightBrownButtonBlank onClick={() => deleteBreed(breedId)}>
                        Удалить
                    </LightBrownButtonBlank>
                </ButtonGroup>}
        </div>
    );
};

export default DeleteBreedPopupBody;