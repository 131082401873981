import React from 'react';
import CalendarInput from "../../../../../components/common/form/parts/inputs/CalendarInput";
import {ERROR_CLASS, INPUT_CLASS} from "../../../../../utils/stringutils";
import {PET_BIRTH_DATE} from "../../../newpet/helpers/petFormConstants";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import FormItem from "../../../../../components/common/form/parts/inputs/wrappers/FormItem";

/**
 * Компонент формы "Дата рождения питомца"
 */
const PetBirthDateFormItem = ({errors, register, tabIndex, onBlurFunction = null, hasValue}) => {
    return (
        <FormItem>
            <CalendarInput classes={errors[PET_BIRTH_DATE] ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                           register={register} fieldName={PET_BIRTH_DATE} placeholder="Дата рождения"
                           tabIndex={tabIndex} onBlurFunction={onBlurFunction} hasValue={hasValue}/>
            {errors[PET_BIRTH_DATE] ? <FormError title={errors[PET_BIRTH_DATE].message}/> : null}
        </FormItem>
    );
};

export default PetBirthDateFormItem;