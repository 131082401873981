import React from 'react';
import RadioButtons from "../../../../../components/common/form/parts/inputs/RadioButtons";
import {LIVING_ENVIRONMENT} from "../../../newpet/helpers/petFormConstants";
import {useTranslation} from "react-i18next";

/**
 * Компонент формы "Место проживания питомца"
 */
const PetLivingEnvironmentFormItem = ({
                                          register, errors, toStore = false, hookFormSetValue,
                                          needLabel = true
                                      }) => {
    const {t} = useTranslation();
    const possibleLivingEnvironmentVars = [
        {value: "CITY", title: t('enums.livingEnvironment.city')},
        {value: "COUNTRYSIDE", title: t('enums.livingEnvironment.countryside')}
    ];

    return (
        <RadioButtons register={register} fieldName={LIVING_ENVIRONMENT}
                      label={needLabel ? t('page.pet.newPet.petLivingEnvironment') : ""}
                      values={possibleLivingEnvironmentVars} toStore={toStore} hookFormSetValue={hookFormSetValue}
                      errors={errors}/>

    );
};

export default PetLivingEnvironmentFormItem;