import React from 'react';
import {PET_GENDER} from "../../petConst";
import {hasValue} from "../../../../../app/helper/commonHelper";
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getDatePrettyFormat} from "../../../../../app/helper/dateHelper";

/**
 * Блок с базовой информацией о питомце
 */
const BasicInfoBlock = ({pet, breed}) => {
    const {t} = useTranslation();
    const params = useParams();
    const infoMap = [
        {
            title: 'Кличка',
            value: pet.name,
            hasValue: hasValue(pet.name),
            onClickPath: `/pets/${params.id}/name`
        },
        {
            title: 'Порода',
            value: breed?.translation?.breedTitle,
            hasValue: hasValue(breed?.translation?.breedTitle),
            onClickPath: `/pets/${params.id}/breed`
        },
        {
            title: 'Пол',
            value: t(PET_GENDER[pet.petGender]),
            hasValue: hasValue(PET_GENDER[pet.petGender]),
            onClickPath: `/pets/${params.id}/gender`
        },
        {
            title: 'Дата рождения',
            value: getDatePrettyFormat(pet?.birthDate),
            hasValue: hasValue(pet?.birthDate),
            onClickPath: `/pets/${params.id}/birthdate`
        }
    ];
    return (
        <InformationBlock title='Базовая информация' infoMap={infoMap}/>
    );
};

export default BasicInfoBlock;