import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getActivePetForUpdatePetInfo} from "../../../newpet/helpers/petHelper";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import {DEFAULT} from "../../../../../app/const/appConst";
import BreedService from "../../../../breeds/api/BreedService";
import {isEmptyOrNull, hasValue} from "../../../../../app/helper/commonHelper";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {useSortedBreeds} from "../../../../breeds/hooks/useBreeds";
import PetService from "../../../newpet/api/PetService";
import {updatePetBreed} from "../../../../../app/store/userReducer";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import PetAnimalKindFormItem from "../../../common/components/form/PetAnimalKindFormItem";
import PetBreedFormItem from "../../../common/components/form/PetBreedFormItem";

/**
 * Работа с породой питомца
 */
const PetBreed = () => {
    const {i18n, t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [breedErrorMessage, setBreedErrorMessage] = useState("");
    const [breeds, setBreeds] = useState([]);
    const [breed, setBreed] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [animalKind, setAnimalKind] = useState(DEFAULT);
    const {
        handleSubmit
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const sortedBreeds = useSortedBreeds(breeds, 'name');

    function clearErrors() {
        setErrorMessage("");
        setBreedErrorMessage("");
    }

    /**
     * Зачистить данные перед загрузкой страницы
     */
    function clearData() {
        setBreeds([]);
    }

    function isButtonActive() {
        return hasValue(breed) && activePet.breedId !== Number(breed);
    }

    function getBreedValue() {
        if (isEmptyOrNull(breed)) {
            return activePet.breedId;
        }
        return breed;
    }

    function init() {
        BreedService.getAnimalKindAllBreedsByExample(activePet.breedId, i18n.language)
            .then((resp) => {
                const data = resp.data.data;
                setAnimalKind(data.animalKind);
                data.breedsViewDto.forEach(breedView => setBreeds(prevState => ([...prevState, {
                    value: breedView.breedId,
                    name: breedView.title
                }])));
            }, (error) => {
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            });
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearData();
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function submitForm() {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetBreed(activePet.id, breed, token);
        response.then((resp) => {
            dispatch(updatePetBreed({
                breedId: resp.data.data
            }))
        }, (error) => {
            setBreedErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.pet.breed')} backTo={`/pets/${params.id}`}
                                     content={t('page.pet.canEditBreed')}/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <PetAnimalKindFormItem animalKind={animalKind} disabled={true}/>
                <PetBreedFormItem value={getBreedValue()} onChangeFunction={setBreed}
                                  errorMessage={breedErrorMessage}
                                  options={sortedBreeds} disabled={isLoading}/>
                <FormError title={breedErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetBreed;