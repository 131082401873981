import React from 'react';
import UserRegisterForm from "../registrationform/UserRegisterForm";
import UserRegisterFormTitle from "../registrationform/UserRegisterFormTitle";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {showModal} from "../../../../../app/store/modalReducer";

/**
 * Заголовок для формы авторизации пользователей
 * используется для модального окна
 */
const LoginFormTitle = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function showRegisterForm() {
        dispatch(showModal({
            popup: {
                title: <UserRegisterFormTitle/>,
                body: <UserRegisterForm/>
            }
        }))
    }

    return (
        <div>
            <span className="link active-link">{t("form.login.title")}</span>
            <span className="link"
                  onClick={() => showRegisterForm()}>{t("form.registration.title")}</span>
        </div>
    );
};

export default LoginFormTitle;