import React from 'react';

/**
 * Скрытый инпут
 */
const HiddenInput = ({fieldName, register}) => {
    return (
        <input type="hidden"
               {...register(fieldName)} />
    );
};

export default HiddenInput;