import React from 'react';

/**
 * Оранжевая кнопка
 */
const OrangeButtonByCenter = ({isActive, children, ...props}) => {
    return (
        <div {...props} className={isActive? "orange-btn center" : "orange-btn center disabled"}>
            <span className="orange-btn__label">{children}</span>
        </div>
    );
};

export default OrangeButtonByCenter;