import {useMemo} from 'react';

// кастомные пользовательские хуки - это хуки, которые внутри себя
// используют стандартные хуки реакта
//сортировка постов по переданному параметру sort
export const useSortedBreeds = (breedsInfo, sort) => {
    return useMemo(() => {
        if (sort) {
            return [...breedsInfo].sort((a, b) => a[sort].localeCompare(b[sort]));
        }
        return breedsInfo;
    }, [breedsInfo, sort]);
}

//сортировка и фильтрация по названию и типу животного
export const useBreeds = (breedsInfo, sort, query, animalKind) => {
    const sortedBreeds = useSortedBreeds(breedsInfo, sort);

    // sorted And Searched Breeds
    return useMemo(() => {
        if (query && query.length > 1) {
            return sortedBreeds.filter(breed => breed.title.toLowerCase().includes(query.toLowerCase()));
        }
        return sortedBreeds;
        //TODO: посмотреть как будет отрабатывать без animalKind в аргументах
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, sortedBreeds, animalKind]);
}

