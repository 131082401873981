import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {STRING} from "../../../../../../components/common/form/helper/formConstants";
import {navigateToTheNextStep, petFormStepOneSchema, removeCurrentStepFromValidated} from "../../../helpers/petHelper";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import FormError from "../../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {getStoredItem, storeItem} from "../../../../../../components/common/form/helper/formHelper";
import {
    NEW_PET_STEP_TWO_PATH,
    PET_ANIMAL_KIND,
    PET_BREED,
    PET_GENDER,
    PET_HOME_NAME
} from "../../../helpers/petFormConstants";
import {DEFAULT} from "../../../../../../app/const/appConst";
import BreedService from "../../../../../breeds/api/BreedService";
import {useNavigate} from "react-router";
import PetForm from "../PetForm";
import TextInputFormItem from "../../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import PetAnimalKindFormItem from "../../../../common/components/form/PetAnimalKindFormItem";
import PetBreedFormItem from "../../../../common/components/form/PetBreedFormItem";
import PetGenderFormItem from "../../../../common/components/form/PetGenderFormItem";
import OrangeButtonByCenter from "../../../../../../components/common/ui/buttons/OrangeButtonByCenter";

/**
 * Форма добавления нового питомца
 */
const PetFormStepOne = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [animalKind, setAnimalKind] = useState(DEFAULT); //нужно, чтобы отрисовывалось значение по умолчанию
    const [breed, setBreed] = useState(DEFAULT); //нужно, чтобы отрисовывалось значение по умолчанию
    const CURRENT_STEP = 1;
    const [gender, setGender] = useState(getStoredItem(PET_GENDER, STRING));
    const breedsInfo = useRef([]);
    const [disableBreeds, setDisableBreeds] = useState(true); // блокирование поля с породами
    const formValid = useRef(false);
    const [breedErrorMessage, setBreedErrorMessage] = useState("");
    const [breedInfoErrorMessage, setBreedInfoErrorMessage] = useState("");
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petFormStepOneSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function additionalValidation() {
        if (breed === "" || breed === DEFAULT) {
            setBreedErrorMessage("Необходимо заполнить сведения о породе")
            formValid.current = false;
        }
    }

    /**
     * Зачищаем тексты ошибок полей, которые обрабатываются вне движка формы
     */
    function clearErrorMessages() {
        formValid.current = true;
        setBreedErrorMessage("");
    }

    function submitForm() {
        clearErrorMessages();
        additionalValidation();

        if (formValid.current) {
            navigateToTheNextStep(CURRENT_STEP, navigate, NEW_PET_STEP_TWO_PATH);
        }
    }

    /**
     * Подставляем данные из стора, если они были
     */
    function init() {
        setValue(PET_HOME_NAME, getStoredItem(PET_HOME_NAME, STRING));
        setValue(PET_GENDER, getStoredItem(PET_GENDER, STRING));
        const storedAnimalKind = getStoredItem(PET_ANIMAL_KIND, STRING);
        if (storedAnimalKind.length !== 0) {
            setAnimalKind(storedAnimalKind);
            getBreeds(storedAnimalKind);
        }
    }

    function hasPetHomeNameValue() {
        return getStoredItem(PET_HOME_NAME, STRING) !== ''
    }

    //Для того чтобы не дублировались записи в селект боксе
    function clearBreedInfo() {
        setBreedInfoErrorMessage('')
        breedsInfo.current = [];
    }

    function clearBreedData() {
        storeItem(PET_BREED, DEFAULT);
        setBreed(DEFAULT);
    }

    //пытаемся восстановить данные о породе
    function restoreBreedInfo(breedsInfo) {
        const storedBreed = getStoredItem(PET_BREED, STRING);
        if (storedBreed.length !== 0 && storedBreed !== DEFAULT) {
            //фильтр
            if (!breedsInfo.current.some(el => el.value === Number(storedBreed))) {
                clearBreedData()
            } else {
                setBreed(storedBreed);
            }
        }
    }

    function getBreeds(animalKind) {
        clearBreedInfo();
        const response = BreedService.getAll(animalKind, i18n.language);
        response.then((resp) => {
            resp.data.data.map(breedInfo => {
                breedsInfo.current.push({value: breedInfo.breedId, name: breedInfo.title})
                // return null - для Lint'a. Посмотреть как правильнее возвращать результат где он не предполагается
                return null;
            })
            setDisableBreeds(false);
            restoreBreedInfo(breedsInfo);
        }, (error) => {
            setBreedInfoErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            clearBreedData();
            setDisableBreeds(true);
        });
    }

    function onChangeAnimalKind(selectedSort) {
        setAnimalKind(selectedSort);
        getBreeds(selectedSort);
    }

    function onChangeBreed(selectedSort) {
        setBreed(selectedSort);
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <TextInputFormItem errors={errors} fieldName={PET_HOME_NAME} placeholder={t('page.pet.newPet.name')}
                               register={register} hasValue={hasPetHomeNameValue()} tabIndex='1'
                               onBlurFunction={storeItem}/>
            <PetAnimalKindFormItem animalKind={animalKind} onChangeFunction={onChangeAnimalKind}
                                   onBlurFunction={storeItem}/>
            <PetBreedFormItem value={breed} onChangeFunction={onChangeBreed}
                              onBlurFunction={storeItem} errorMessage={breedInfoErrorMessage}
                              options={breedsInfo.current} disabled={disableBreeds}/>
            <FormError title={breedErrorMessage}/>
            <PetGenderFormItem value={gender} setValue={setGender} register={register}
                               hookFormSetValue={setValue} toStore={true} errors={errors}/>

            <OrangeButtonByCenter isActive={true} onClick={handleSubmit(submitForm)}>
                Вперёд
            </OrangeButtonByCenter>

        </PetForm>
    );
};

export default PetFormStepOne;