import React from 'react';
import {useLocation} from "react-router-dom";
import {addPetSteps} from "../helpers/petFormConstants";
import {useSelector} from "react-redux";

/**
 * Информация о том на каком шаге мы сейчас находимся
 */
const StepInfo = () => {
    const location = useLocation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    if (isMobile) {

        return (
            <div className="add-pet-step-info__wrapper mobile">
                {addPetSteps.map(step => {
                    if (location.pathname === step.path) {
                        return (<div key={step.number} className="pet-step-link mobile active-step">
                            <span>{'Шаг ' + step.number + '. ' + step.title}</span>
                        </div>);
                    } else {
                        return null;
                    }
                })}
                <div className="add-pet-step-info__mobile">
                    {addPetSteps.map(step => {
                        if (location.pathname !== step.path) {
                            return (<span key={step.number} className="dot"></span>);
                        } else {
                            return (<span key={step.number} className="dot active"></span>);
                        }
                    })}
                </div>
            </div>
        );
    } else {
        return (
            <div className="add-pet-step-info__wrapper">
                {addPetSteps.map(step => {
                    if (location.pathname !== step.path) {
                        return <div className="add-pet-step-info" key={step.number}>
                            <span className="dot"></span>
                            <span className="pet-step-link">{step.title}</span>
                        </div>
                    } else {
                        return <div className="add-pet-step-info" key={step.number}>
                            <span className="dot active"></span>
                            <span className="pet-step-link active-step">{step.title}</span>
                        </div>
                    }
                })
                }
            </div>
        );
    }
};

export default StepInfo;