import React from 'react';
import OrangeButtonFilled from "../../../ui/buttons/OrangeButtonFilled";
import {showModal} from "../../../../../app/store/modalReducer";
import LoginFormTitle from "../../../form/completeforms/loginform/LoginFormTitle";
import LoginForm from "../../../form/completeforms/loginform/LoginForm";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

/**
 * Кнопка входа
 */
const LoginButton = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    function showLoginForm() {
        dispatch(showModal({
            popup: {
                title: <LoginFormTitle/>,
                body: <LoginForm/>
            }
        }))
    }

    return (
        <div className="login-btn-wrapper">
            <OrangeButtonFilled
                onClick={() => showLoginForm()}>
                {t("button.login")}
            </OrangeButtonFilled>
        </div>

    );
};

export default LoginButton;