import React, {useEffect, useState} from 'react';
import {makeBlurLogic, movePlaceholderUp} from "../../helper/formHelper";
import {LABEL_CLASSNAME_WITH_VALUE, LABEL_CLASSNAME_WITHOUT_VALUE} from "../../helper/commonFormConstants";

/**
 * Компонент textarea
 */
const TextArea = ({
                      register, fieldName,
                      placeholder, hasValue = false,
                      storeFunction = null, tabIndex = null
                  }) => {

    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE)

    useEffect(() => {
        setLabelClass(hasValue ?
            LABEL_CLASSNAME_WITH_VALUE : LABEL_CLASSNAME_WITHOUT_VALUE)
    }, [hasValue])
    return (
        <>
            <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
            <textarea
                id={fieldName}
                className='form__textarea'
                {...register(fieldName)}
                onBlur={(e) => makeBlurLogic(e.target.value, setLabelClass, fieldName, storeFunction)}
                onFocus={(e) => movePlaceholderUp(setLabelClass)} // e - обязательно, иначе бесконечная перерисовка компонента
                tabIndex={tabIndex}/>
        </>
    );
};

export default TextArea;