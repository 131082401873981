import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getActivePetForUpdatePetInfo} from "../../../newpet/helpers/petHelper";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import PetService from "../../../newpet/api/PetService";
import {updatePetGender} from "../../../../../app/store/userReducer";
import PetGenderFormItem from "../../../common/components/form/PetGenderFormItem";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import {useTranslation} from "react-i18next";

/**
 * Работа с гендером питомца
 */
const PetGender = () => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [genderErrorMessage, setGenderErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [gender, setGender] = useState();
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage("");
        setGenderErrorMessage("");
    }

    function isButtonActive() {
        return activePet.petGender !== gender;
    }

    function init() {
        setGender(activePet.petGender);
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetGender(activePet.id, data.petGender, token);
        response.then((resp) => {
            const data = resp.data.data;

            dispatch(updatePetGender({
                petGender: data
            }))
        }, (error) => {
            setGenderErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.pet.petGender')} backTo={`/pets/${params.id}`}
                                     content={t('page.pet.canEditGender')}/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <PetGenderFormItem value={gender} setValue={setGender} register={register}
                                   hookFormSetValue={setValue} errors={errors}/>
                <FormError title={genderErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetGender;