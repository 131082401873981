import React from 'react';

/**
 * Компонент блока ссылок для мобильной версии
 */
const MobileSectionLinks = ({isActive, fitToCenter = false, children}) => {
    function formClassName() {
        let clName = 'mobile-section__links';
        clName = isActive ? clName + ' active' : clName;
        clName = fitToCenter ? clName + ' center' : clName;
        return clName;
    }

    return (
        <div className={formClassName()}>
            {children}
        </div>
    );
};

export default MobileSectionLinks;