import React, {useEffect} from 'react';
import {
    addPetSteps,
    LIVING_CONDITIONS,
    LIVING_ENVIRONMENT,
    NEW_PET_STEP_FOUR_PATH
} from "../../../helpers/petFormConstants";
import PetForm from "../PetForm";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {
    navigateToTheNextStep,
    petFormStepThreeSchema,
    removeCurrentStepFromValidated,
    stepBack
} from "../../../helpers/petHelper";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import ButtonGroup from "../../../../../../components/common/ui/buttons/ButtonGroup";
import OrangeButtonFilled from "../../../../../../components/common/ui/buttons/OrangeButtonFilled";
import LightBrownButton from "../../../../../../components/common/ui/buttons/LightBrownButton";
import {getStoredItem} from "../../../../../../components/common/form/helper/formHelper";
import {STRING} from "../../../../../../components/common/form/helper/formConstants";
import PetLivingConditionsFormItem from "../../../../common/components/form/PetLivingConditionsFormItem";
import PetLivingEnvironmentFormItem from "../../../../common/components/form/PetLivingEnvironmentFormItem";

/**
 * Второй шаг добавления нового питомца
 */
const PetFormStepThree = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const CURRENT_STEP = 3;
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petFormStepThreeSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    /**
     *  Поскольку все поля данного шага обрабатываются движком хука формы,
     *  нам не нужна дополнительная валидация. Метод будет вызываться тогда,
     *  когда все данные будут валидны
     */
    function submitForm() {
        navigateToTheNextStep(CURRENT_STEP, navigate, NEW_PET_STEP_FOUR_PATH);
    }

    function init() {
        setValue(LIVING_ENVIRONMENT, getStoredItem(LIVING_ENVIRONMENT, STRING));
        setValue(LIVING_CONDITIONS, getStoredItem(LIVING_CONDITIONS, STRING));
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <PetLivingEnvironmentFormItem register={register} errors={errors} toStore={true}
                                          hookFormSetValue={setValue}/>
            <PetLivingConditionsFormItem register={register} errors={errors} toStore={true}
                                         hookFormSetValue={setValue}/>
            <ButtonGroup>
                <LightBrownButton onClick={() => stepBack(CURRENT_STEP, addPetSteps, navigate)}>
                    Назад
                </LightBrownButton>
                <OrangeButtonFilled onClick={handleSubmit(submitForm)}>
                    Вперёд
                </OrangeButtonFilled>
            </ButtonGroup>
        </PetForm>
    );
};

export default PetFormStepThree;