import React from 'react';
import TableTitleOneColNoActions from "./TableTitleOneColNoActions";
import TableTitleOneColWithActions from "./TableTitleOneColWithActions";

/**
 * Динамический заголовок для таблицы с одной колонкой
 * col_1: 100%
 */
const TableTitleOneCol = ({title}) => {
    if (title.actions === undefined || title.actions === null || title.actions === '') {
        return <TableTitleOneColNoActions title={title}/>
    }
    return <TableTitleOneColWithActions title={title}/>
};

export default TableTitleOneCol;