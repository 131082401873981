import React from 'react';

/**
 * Зелёная галочка, символизирующая успешность операции
 */
const DoneImage = () => {
    return (
        <div className="success-registration__done"></div>
    );
};

export default DoneImage;