import React from 'react';
import FormError from "../inputs/wrappers/FormError";
import Spinner from "../../../Spinner";
import SubmitButton from "../../../ui/buttons/SubmitButton";

/**
 * Секция управления формой,
 * в которой находится кнопка-submit и вывод возможной ошибки
 */
const SubmitSection = ({hasFormError, errorMessage, isFormLoading, submitLabel, isSubmitDisabled = false}) => {
    return (
        <>
            {hasFormError ? <FormError title={errorMessage}/> : null}
            {isFormLoading ? <Spinner/> :
                <SubmitButton label={submitLabel} disabledSubmit={isSubmitDisabled}/>
            }
        </>
    );
};

export default SubmitSection;