import React, {useEffect, useState} from 'react';
import {
    addPetSteps,
    IS_PET_NEUTER,
    NEW_PET_STEP_THREE_PATH,
    NUTRITION_TYPE,
    PET_BIRTH_DATE,
    PET_WEIGHT
} from "../../../helpers/petFormConstants";
import {getStoredItem, storeItem} from "../../../../../../components/common/form/helper/formHelper";
import PetForm from "../PetForm";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {
    navigateToTheNextStep,
    petFormStepTwoSchema,
    removeCurrentStepFromValidated,
    stepBack
} from "../../../helpers/petHelper";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {NUMBER, STRING} from "../../../../../../components/common/form/helper/formConstants";
import ButtonGroup from "../../../../../../components/common/ui/buttons/ButtonGroup";
import OrangeButtonFilled from "../../../../../../components/common/ui/buttons/OrangeButtonFilled";
import LightBrownButton from "../../../../../../components/common/ui/buttons/LightBrownButton";
import CustomSelectorTwoVarsInTable from "../../../../../../components/common/ui/select/CustomSelectorTwoVarsInTable";
import PetBirthDateFormItem from "../../../../common/components/form/PetBirthDateFormItem";
import NumberInputFormItem from "../../../../../../components/common/form/parts/inputs/wrappers/NumberInputFormItem";
import PetNutritionTypeFormItem from "../../../../common/components/form/PetNutritionTypeFormItem";

/**
 * Второй шаг добавления нового питомца
 */
const PetFormStepTwo = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const NEUTERED = {value: "NEUTERED", title: t('enums.neuterStatus.neutered')}
    const NOT_NEUTERED = {value: "NOT_NEUTERED", title: t('enums.neuterStatus.notNeutered')}
    const [neuterStatus, setNeuterStatus] = useState(getStoredItem(IS_PET_NEUTER, STRING));
    const CURRENT_STEP = 2;
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petFormStepTwoSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    /**
     *  Поскольку все поля данного шага обрабатываются движком хука формы,
     *  нам не нужна дополнительная валидация. Метод будет вызываться тогда,
     *  когда все данные будут валидны
     */
    function submitForm() {
        navigateToTheNextStep(CURRENT_STEP, navigate, NEW_PET_STEP_THREE_PATH);
    }

    function hasBirthDateValue() {
        return getStoredItem(PET_BIRTH_DATE, STRING) !== '';
    }

    function hasWeightValue() {
        return getStoredItem(PET_WEIGHT, NUMBER) !== 0 && getStoredItem(PET_WEIGHT, NUMBER) !== '';
    }


    function init() {
        setValue(PET_BIRTH_DATE, getStoredItem(PET_BIRTH_DATE, STRING));
        setValue(PET_WEIGHT, getStoredItem(PET_WEIGHT, NUMBER));
        setValue(IS_PET_NEUTER, getStoredItem(IS_PET_NEUTER, STRING));
        setValue(NUTRITION_TYPE, getStoredItem(NUTRITION_TYPE, STRING));
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <PetBirthDateFormItem errors={errors} register={register} tabIndex='1'
                                  onBlurFunction={storeItem} hasValue={hasBirthDateValue()}/>

            <NumberInputFormItem fieldName={PET_WEIGHT} register={register}
                                 placeholder={t('page.pet.newPet.weightInGrams')} onBlurFunction={storeItem}
                                 tabIndex='2' hasValue={hasWeightValue()} errors={errors}/>
            {/*Стерилизован ли питомец*/}
            <CustomSelectorTwoVarsInTable title={t('enums.neuterStatus.getStatus')}
                                          value={neuterStatus} setValue={setNeuterStatus} first={NEUTERED}
                                          second={NOT_NEUTERED} fieldName={IS_PET_NEUTER} toStore={true}
                                          register={register} hookFormSetValue={setValue} errors={errors}/>
            <PetNutritionTypeFormItem register={register} hookFormSetValue={setValue} errors={errors}/>

            <ButtonGroup>
                <LightBrownButton onClick={() => stepBack(CURRENT_STEP, addPetSteps, navigate)}>
                    {t('button.back')}
                </LightBrownButton>
                <OrangeButtonFilled onClick={handleSubmit(submitForm)}>
                    {t('button.forward')}
                </OrangeButtonFilled>
            </ButtonGroup>
        </PetForm>
    );
};

export default PetFormStepTwo;