import React from 'react';

/**
 * Белая кнопка со светло-коричневой окантовкой.
 * При наведении становится светло-коричневой
 */
const LightBrownButtonBlank = ({children, ...props}) => {
    return (
        <div {...props} className="light-brown-btn reverse">
            <span className="light-brown-btn__label">{children}</span>
        </div>
    );
};

export default LightBrownButtonBlank;