import React, {useEffect} from 'react';
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PageTitle from "../../components/common/PageTitle";
import {useTranslation} from "react-i18next";
import Content from "../../components/common/Content";
import BreedForm from "./components/form/BreedForm";
import {ACTION_ADD} from "../../components/common/form/helper/formConstants";

/**
 * Страница добавления новой породы животного
 */
const NewBreed = () => {
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    return (
        <Content>
            <Breadcrumbs elements={[
                {page: t("page.breeds.title"), path: '/breeds'},
                {page: t("form.newBreed.title"), path: ''}
            ]}/>
            <PageTitle title={t("form.newBreed.newBreed")}/>
            <BreedForm action={ACTION_ADD}/>
        </Content>
    );
};

export default NewBreed;