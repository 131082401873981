import React from 'react';

/**
 * Выводит сообщение об успешно выполненной операции по центру.
 * Черным цветом
 */
const SuccessMessageByCenter = ({message}) => {
    return (
        <div className="success-message black">
            <span>{message}</span>
        </div>
    );
};

export default SuccessMessageByCenter;