import React, {useEffect, useState} from 'react';
import {makeBlurLogic, movePlaceholderUp} from "../../helper/formHelper";
import {useDispatch} from "react-redux";
import {LABEL_CLASSNAME_WITH_VALUE, LABEL_CLASSNAME_WITHOUT_VALUE} from "../../helper/commonFormConstants";

/**
 * Компонент текстового инпута с лейблом который используется с хуком useForm
 */
const TextInput = ({
                       classes, fieldName, register, placeholder,
                       onBlurFunction = null, tabIndex = null,
                       dispatchFunction = null, hasValue
                   }) => {
    const dispatch = useDispatch();

    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE)

    useEffect(() => {
        setLabelClass(hasValue ?
            LABEL_CLASSNAME_WITH_VALUE : LABEL_CLASSNAME_WITHOUT_VALUE)
    }, [hasValue])


    return (
        <>
            <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
            <input type="text"
                   id={fieldName}
                   className={classes}
                   {...register(fieldName)}
                   onBlur={(e) => makeBlurLogic(e.target.value, setLabelClass, fieldName,
                       onBlurFunction, dispatch, dispatchFunction)}
                   onFocus={() => movePlaceholderUp(setLabelClass)}
                   tabIndex={tabIndex}
                   autoComplete="off"/>
        </>
    );
};

export default TextInput;