import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setBurgerActive} from "../../../../app/store/burgerReducer";

/**
 * Кнопка - бургер, которая появляется в мобильной версии
 */
const BurgerButton = () => {
    const dispatch = useDispatch();
    const isActive = useSelector(state => state.burgerReducer.isBurgerActive);
    const toggleClass = () => {
        dispatch(setBurgerActive(!isActive))
    };

    const rootClasses = ['menu-logo-burger-container__burger'];
    if (isActive) {
        rootClasses.push('active');
    }

    return (
        <div className={rootClasses.join((' '))}
             onClick={() => toggleClass()}>
            <span></span>
        </div>
    );
};

export default BurgerButton;