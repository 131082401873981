import React from 'react';

const PageTitle = (props) => {
    return (
        <div className="section-title">
            <div className="greetings">
                <h1>{props.title}</h1>
            </div>
        </div>
    );
};

export default PageTitle;