import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BreedService from "./api/BreedService";
import Content from "../../components/common/Content";
import {useTranslation} from "react-i18next";
import BreedInfo from "./components/BreedInfo";

/**
 * Информация о породе питомца
 */
const Breed = () => {
    const {i18n} = useTranslation();
    const params = useParams();
    const [img, setImg] = useState({});
    const [breed, setBreed] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isImgLoading, setIsImgLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasTranslation, setHasTranslation] = useState(false);

    function getBreedInfo() {
        setIsLoading(true)
        const response = BreedService.getById(Number(params.id), i18n.language);

        response.then((resp) => {
            const data = resp.data.data;
            setBreed(data)
            setHasTranslation(data.translation !== null && Object.keys(data.translation).length !== 0)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
        setIsLoading(false)
    }

    function getImage() {
        setIsImgLoading(true)
        const response = BreedService.getImageByBreedId(Number(params.id));
        response.then((resp) => {
            if (resp.data.size === 0) {
                setImg({})
                return;
            }
            let header = resp.headers['content-disposition'];
            const myBlob = resp.data;
            myBlob.name = header.substring(header.indexOf('=') + 1)
            myBlob.lastModified = new Date();

            const fileImage = new File([myBlob], myBlob.name, {
                type: myBlob.type
            });
            setImg(URL.createObjectURL(fileImage));
        })
        setIsImgLoading(false)
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        //чтобы не перезагружать картинку
        if (Object.keys(img).length === 0) {
            getImage();
        }
        getBreedInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    return (
        <Content>
            {breed?.translation ?
                <BreedInfo breedInfo={breed}
                           image={img}
                           errorMessage={errorMessage}
                           isLoading={isLoading}
                           isImgLoading={isImgLoading}
                           hasTranslation={hasTranslation}/> : null
            }
        </Content>
    );
};

export default Breed;