import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";
import {useNavigate} from "react-router";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare";

const CircleTemplateForImage = ({image = null, navigatePath = null}) => {
    const navigate = useNavigate();
    return (
        <div className="circle-photo__wrapper">
            {image === null ?
                <div className="circle-photo__add-button"
                     onClick={() => navigatePath === null ? null : navigate(navigatePath)}>
                    <FontAwesomeIcon icon={faCamera} className="fa-camera"/>
                </div>
                :
                <div className="circle-image-item">
                    <FontAwesomeIcon icon={faPenToSquare} className="fa-pencil"
                                     onClick={() => navigatePath === null ? null : navigate(navigatePath)}/>
                    <img src={`data:image/jpeg;base64,${image.fileBase64}`} className="img-circle" alt=""/>
                </div>
            }
        </div>
    );
};

export default CircleTemplateForImage;