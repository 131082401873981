import React from 'react';
import Form from "../../../../../components/common/form/abstract/Form";

/**
 * Компонент-оболочка обычной формы
 */
const PetForm = ({handleSubmit, submitForm, children}) => {
    return (
        <div className="plain-form">
            <Form submitForm={submitForm} handleSubmit={handleSubmit}>{children}</Form>
        </div>
    );
};

export default PetForm;