import React from 'react';

/**
 * Блок ссылок мобильной версии
 */
const MobileLinksBlock = ({children}) => {
    return (
        <div className='mobile-section__links-block'>
            {children}
        </div>
    );
};

export default MobileLinksBlock;