import React from 'react';
import Spinner from "../../../Spinner";
import OrangeButtonByCenter from "../../../ui/buttons/OrangeButtonByCenter";

/**
 * Кнопка submit на форме
 */
const FormSubmitButton = ({title, isLoading, isButtonActive, handleSubmit, submitForm}) => {
    if (isLoading) {
        return <Spinner/>;
    } else {
        return <OrangeButtonByCenter isActive={isButtonActive()} onClick={handleSubmit(submitForm)}>
            {title}
        </OrangeButtonByCenter>
    }
};
export default FormSubmitButton;