import React from 'react';

/**
 * Контейнер для элемента формы
 */
const FormItem = ({children, classes = null}) => {
    return (
        <div className={classes === null ? "form-item" : classes}>
            {children}
        </div>
    );
};

export default FormItem;