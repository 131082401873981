import React, {useState} from 'react';
import {movePlaceholderUp} from "../../helper/formHelper";

/**
 * Компонент инпута календаря
 */
const CalendarInput = ({
                           classes, fieldName, register, placeholder,
                           onBlurFunction = null, tabIndex = null,
                           hasValue = false
                       }) => {
    const [labelClass, setLabelClass] = useState(hasValue ?
        'focused form-item__label' : 'form-item__label')

    function makeBlurLogic(value) {
        if (value === '') {
            setLabelClass('form-item__label');
        }
        if (onBlurFunction !== null && onBlurFunction !== undefined) {
            onBlurFunction(fieldName,value);
        }
    }

    return (
        <>
            <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
            <input type="date"
                   className={classes}
                   placeholder={placeholder}
                   {...register(fieldName)}
                   onBlur={(e) => makeBlurLogic(e.target.value)}
                   onFocus={(e) => movePlaceholderUp(setLabelClass)}
                   tabIndex={tabIndex}/>
        </>
    );
};

export default CalendarInput;