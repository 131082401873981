import React from "react";
import "./style/css/style.css";
import Header from "./components/common/header/Header";
import {BrowserRouter} from "react-router-dom";
import Footer from "./components/common/footer/Footer";
import MainContainer from "./components/common/MainContainer";
import AppRouter from "./components/common/router/AppRouter";


// react-transition-group - можно анимировать списки, отслеживать фазы анимации
// axios - библиотека, с помощью которой можно делать запросы
// react router - библиотека для управления роутингом в браузере
//https://jsonplaceholder.typicode.com/ - здесь можно брать фейковые данные для API

//Жизненный цикл компонента
// mount - создание и монтирование в ДОМ
// (можем отправить запрос на сервер, повесить слушатель события)

// update - обновление компонента - изменение состояния - активная стадия жизни компонента
// (изменение зависимостей, произведение действий)

// unmount - когда он больше не нужен и мы его удаляем
// (отписка от слушателя событий, очистка глобального хранилища)

//useEffect(callback, deps) - позволяет следить за ЖЦ компонента.
//если deps пуст, то callback отработает лишь раз

//Service Worker - фоновый процесс, который продолжает работать даже после закрытия браузера
//push-уведомления, фоновая синхронизация
export default function App() {
    return (
        <BrowserRouter>
            <MainContainer>
                <Header/>
                <AppRouter/>
                <Footer/>
            </MainContainer>
        </BrowserRouter>
    );
    // return <UnderConstructionIndexPage/>;
}
