import React from 'react';
import TableNoResults from "../../table/TableNoResults";
import Content from "../../Content";

/**
 * Контент страниц с частичным обновлением информации
 */
const PartInfoContent = ({errorMessage, children}) => {
    return (
        <Content>
            {errorMessage !== "" ? <TableNoResults title={errorMessage}/> : <>{children}</>}
        </Content>
    );
};

export default PartInfoContent;