import React from 'react';
import {useNavigate} from "react-router-dom";
import TableLine from "../../../../../../components/common/table/simple/line/TableLine";
import TableCell from "../../../../../../components/common/table/simple/cells/TableCell";
import {COUNTRY_METADATA} from "../../geoFormsConstants";
import DeleteItemTableCell from "../../../../../../components/common/table/simple/cells/DeleteItemTableCell";

/**
 * Строка в таблице справочника стран
 */
const CountryLine = ({country}) => {
    const navigate = useNavigate();
    const PATH_TO_TRANSLATIONS = "/panel/dictionaries/countries/" + country.id + "/edit";

    return (
        <TableLine editable={true}>
            <TableCell cellSize='40' onClick={() => navigate(PATH_TO_TRANSLATIONS)}>
                <span>{country.translation.title}</span>
            </TableCell>
            <TableCell cellSize='10' onClick={() => navigate(PATH_TO_TRANSLATIONS)}>
                <span>{country.alpha2Code}</span>
            </TableCell>
            <TableCell cellSize='10' onClick={() => navigate(PATH_TO_TRANSLATIONS)}>
                <span>{country.alpha3Code}</span>
            </TableCell>
            <TableCell cellSize='10' onClick={() => navigate(PATH_TO_TRANSLATIONS)}>
                <span>{country.isoCode}</span>
            </TableCell>
            <TableCell cellSize='20' onClick={() => navigate(PATH_TO_TRANSLATIONS)}>
                <span>{country.continent.translation.title}</span>
            </TableCell>
            <DeleteItemTableCell id={country.id} title={country.translation.title} metadata={COUNTRY_METADATA}/>
        </TableLine>
    );
};

export default CountryLine;