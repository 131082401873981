import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {Link} from "react-router-dom";

/**
 * Кнопка в виде "+" которая используется если нужно добавить элемент.
 * Редиректит на форму с добавлением элемента
 */
const AddElementLink = ({goto}) => {
    return (
        <Link className="simple-link" to={goto}>
            <FontAwesomeIcon icon={faPlus} className="fa-2x"/>
        </Link>
    );
};

export default AddElementLink;