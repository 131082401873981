import React from 'react';
import Spinner from "../../../../../components/common/Spinner";
import InformationBlocks from "../../../../../components/common/ui/text/informationblock/InformationBlocks";
import BasicInfoBlock from "../infoblocks/BasicInfoBlock";
import IdentificationBlock from "../infoblocks/IdentificationBlock";
import LivingConditionsBlock from "../infoblocks/LivingConditionsBlock";

/**
 * Вкладка "Базовая информация о питомце"
 */
const PetBasicInfo = ({pet, breed}) => {
    if (pet === undefined) {
        return <Spinner/>;
    }

    return (
        <InformationBlocks>
            <BasicInfoBlock pet={pet} breed={breed}/>
            <IdentificationBlock pet={pet}/>
            <LivingConditionsBlock pet={pet}/>
        </InformationBlocks>
    );
}

export default PetBasicInfo;