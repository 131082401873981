import React, {useState} from 'react';
import {makeBlurLogic, movePlaceholderUp} from "../../helper/formHelper";
import {LABEL_CLASSNAME_WITH_VALUE, LABEL_CLASSNAME_WITHOUT_VALUE} from "../../helper/commonFormConstants";

/**
 * Инпут для пароля
 */
const PasswordInput = ({
                           classes, fieldName, register, placeholder,
                           tabIndex = null, hasValue = false, autocomplete = null
                       }) => {
    const [labelClass, setLabelClass] =
        useState(hasValue ? LABEL_CLASSNAME_WITH_VALUE : LABEL_CLASSNAME_WITHOUT_VALUE);

    return (
        <>
            <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
            <input type="password"
                   id={fieldName}
                   className={classes}
                   {...register(fieldName)}
                   onBlur={(e) => makeBlurLogic(e.target.value, setLabelClass)}
                   onFocus={(e) => movePlaceholderUp(setLabelClass)}
                   autoComplete={autocomplete === null ? null : autocomplete}
                   tabIndex={tabIndex}/>
        </>
    );
};

export default PasswordInput;