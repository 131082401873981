import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getActivePetForUpdatePetInfo, petBirthDateFormSchema} from "../../../newpet/helpers/petHelper";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import {PET_BIRTH_DATE} from "../../../newpet/helpers/petFormConstants";
import PetService from "../../../newpet/api/PetService";
import {updatePetBirthDate} from "../../../../../app/store/userReducer";
import PetBirthDateFormItem from "../../../common/components/form/PetBirthDateFormItem";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {getClearDate, getDateFromDateObject} from "../../../../../app/helper/dateHelper";

/**
 * Работа с датой рождения питомца
 */
const PetBirthDate = () => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        resolver: yupResolver(petBirthDateFormSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage("");
        setBirthDateErrorMessage("");
    }

    function isButtonActive() {
        return getClearDate(activePet.birthDate) !== watch(PET_BIRTH_DATE) && watch(PET_BIRTH_DATE) !== ''
    }

    function init() {
        setValue(PET_BIRTH_DATE, getClearDate(activePet.birthDate));
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetBirthDate(activePet.id, getDateFromDateObject(data.petBirthDate), token);
        response.then((resp) => {
            dispatch(updatePetBirthDate({
                birthDate: resp.data.data
            }))
        }, (error) => {
            setBirthDateErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title="Дата рождения питомца" backTo={`/pets/${params.id}`}
                                     content="Здесь Вы можете отредактировать дату рождения питомца"/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <PetBirthDateFormItem errors={errors} register={register} tabIndex='1'
                                      hasValue={getValues(PET_BIRTH_DATE) !== ''}/>
                <FormError title={birthDateErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetBirthDate;