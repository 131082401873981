import React from 'react';

/**
 * Обертка над информационным блоком
 */
const InformationBlocks = ({children}) => {
    return (
        <div className="information-block-wrapper">
            {children}
        </div>
    );
};

export default InformationBlocks;