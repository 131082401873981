import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Cache from 'i18next-localstorage-cache';
import {initReactI18next} from "react-i18next";
import {USE_DEBUG_FOR_I18NEXT} from "../app/const/main";
//interpolation - позволяет динамически подставлять значения перевода в конструкции {{var}}
//keySeparator - char to separate keys. If working with a flat JSON, it's recommended to set this to false (default: '.')
i18n.use(Backend)
    .use(Cache)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: ["russian", "english"],
        debug: USE_DEBUG_FOR_I18NEXT,
        detection: {
            order: ["localstorage", "cookie"],
            caches: ["localstorage", "cookie"],
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;