import React from 'react';
import TableNoResults from "../../../components/common/table/TableNoResults";
import ButtonGroup from "../../../components/common/ui/buttons/ButtonGroup";
import LightBrownButton from "../../../components/common/ui/buttons/LightBrownButton";
import {useNavigate} from "react-router-dom";

/**
 * Когда что-то пошло не так
 * выводим причину и отправляем на главную страницу
 */
const GoToMainPageWithReasonIfError = ({errorMessage}) => {
    const navigate = useNavigate();
    return (
        <>
            <TableNoResults title={errorMessage}/>
            <ButtonGroup>
                <LightBrownButton onClick={() => navigate("/")}>
                    На главную
                </LightBrownButton>
            </ButtonGroup>
        </>
    );
};

export default GoToMainPageWithReasonIfError;