import React from 'react';
import {LeftMenuRegisterUserLinks} from "./menu/menulinks";
import {Link, useLocation} from "react-router-dom";
import {getIconByAlias} from "../../app/helper/commonHelper";

/**
 * Меню слева
 */
const LeftMenu = () => {
    const location = useLocation();


    function currentPath(path) {
        return location.pathname === path
    }

    return (
        <div className="left-menu-wrapper">
            <nav role="navigation" className="left-menu">
                <ul className="left-menu__links">
                    {LeftMenuRegisterUserLinks.map(page =>
                        <li key={page.title}
                            className={currentPath(page.path) ? "left-menu__item active" : "left-menu__item"}>
                            {currentPath(page.path) ?
                                <span>{getIconByAlias(page.alias)}</span> :
                                <Link to={page.path}>
                                    {getIconByAlias(page.alias)}
                                </Link>
                            }
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default LeftMenu;