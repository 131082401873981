import React from 'react';
import {useNavigate} from "react-router-dom";
import SuccessMessageByCenter from "../../../components/common/ui/SuccessMessageByCenter";
import OrangeButtonByCenter from "../../../components/common/ui/buttons/OrangeButtonByCenter";

/**
 * Сообщение об успешной активации аккаунта пользователя
 */
const ActivationSuccessMessage = ({message, buttonTitle}) => {
    const navigate = useNavigate();

    return (
        <>
            <SuccessMessageByCenter message={message}/>
            <OrangeButtonByCenter isActive={true} onClick={() => navigate("/login")}>
                {buttonTitle}
            </OrangeButtonByCenter>
        </>
    );
};

export default ActivationSuccessMessage;