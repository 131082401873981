import React from 'react';
import CalendarInput from "../CalendarInput";
import {ERROR_CLASS, INPUT_CLASS} from "../../../../../../utils/stringutils";
import FormError from "./FormError";
import FormItem from "./FormItem";

/**
 * Компонент формы "Календарь"
 * надстройка над компонентом CalendarInput
 *
 * Помимо самого инпута снизу выводит текст ошибки если она есть.
 */
const CalendarInputFormItem = ({
                                   fieldName, register, placeholder, tabIndex, onBlurFunction = null,
                                   errors
                               }) => {
    return (
        <FormItem>
            <CalendarInput classes={errors[fieldName] ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                           register={register} fieldName={fieldName}
                           placeholder={placeholder}
                           tabIndex={tabIndex} onBlurFunction={onBlurFunction} hasValue={true}/>
            {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default CalendarInputFormItem;