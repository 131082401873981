import React from 'react';
//компонент таблицы с единственной строкой описывающей отсутствие результатов
const TableNoResults = ({title}) => {
    return (
        <div className="simple-table no-result">
            <div className="table-line">
                <span>{title}</span>
            </div>
        </div>
    );
};

export default TableNoResults;