import React from 'react';
import ModalWindow from "../ui/modal/ModalWindow";
import {useSelector} from "react-redux";
import MobileHeader from "./mobile/MobileHeader";
import DesktopHeader from "./desktop/DesktopHeader";

const Header = () => {
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    return (
        <header id="header">
            {isMobile ? <MobileHeader/> : <DesktopHeader/>}
            <ModalWindow/>
        </header>
    );
};

export default Header;