import React from 'react';

/**
 * Обёртка для таблицы, куда можно добавить название полей и сколько угодно строк
 */
const SimpleTable = ({customClassname = null, children}) => {
    const tableClassName = customClassname === null ? 'simple-table' : customClassname;

    return (
        <div className={tableClassName}>
            {children}
        </div>
    );
};

export default SimpleTable;