import React, {useEffect, useState} from 'react';
import BreedsTable from "./components/breedstable/BreedsTable";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PageTitle from "../../components/common/PageTitle";
import SimpleFilter from "../../components/common/ui/filter/SimpleFilter";
import AnimalKindSelector from "./components/AnimalKindSelector";
import {useBreeds} from "./hooks/useBreeds";
import BreedService from "./api/BreedService";
import Spinner from "../../components/common/Spinner";
import {useTranslation} from "react-i18next";
import Content from "../../components/common/Content";
import {CAT} from "../../app/const/appConst";
import FormItem from "../../components/common/form/parts/inputs/wrappers/FormItem";
import {useSelector} from "react-redux";

/**
 * Страница со списком пород животных
 *
 * При переходе на страницу Breeds должен происходить запрос
 * List<Breeds> getBreedsByType(String animalType) который возвращает список пород
 * конкретного типа животных
 *
 * useMemo(callback, deps) - производит вычисления, запоминает их и кеширует - мемоизация
 * deps - зависимости. Какие-то переменные, поля объекта
 *  каждый раз, когда зависимости меняются функция вновь пересчитает
 * callback -- должен возвращать результат вычислений

 * отсортированный массив отфильтрованный по условию
 *
 **/
const Breeds = () => {
    const {t, i18n} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);

    const [breedsInfo, setBreedsInfo] = useState([]);
    const [filter, setFilter] = useState({sort: '', query: ''})
    const [animalKind, setAnimalKind] = useState(CAT)
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setIsLoading] = useState(false);

    const sortedAndSearchedBreeds = useBreeds(breedsInfo, filter.sort, filter.query, animalKind)

    const sortOptions = [
        {value: 'title', name: t("sort.byTitle")},
        {value: 'origin', name: t("sort.byOrigin")}
    ];
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        const response = BreedService.getAll(animalKind, i18n.language);
        response.then((resp) => {
            setErrorMessage('')
            setBreedsInfo(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });

    }, [animalKind, i18n.language])
    return (
        <Content>
            <Breadcrumbs elements={[{page: t("page.breeds.title"), path: ''}]}/>
            <PageTitle title={t("page.breeds.title")}/>

            <FormItem>
                <AnimalKindSelector animalKind={animalKind} setAnimalKind={setAnimalKind}/>
            </FormItem>

            {isMobile ? null :
                <SimpleFilter sortOptions={sortOptions} filter={filter} setFilter={setFilter}/>}
            {loading ?
                <Spinner/> :
                <BreedsTable breedsInfo={sortedAndSearchedBreeds} error={errorMessage}/>
            }
        </Content>
    );
};

export default Breeds;