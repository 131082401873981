import React from 'react';
import TableTitleTwoCols from "../../../../../components/common/table/simple/title/twocols/TableTitleTwoCols";
import BreedLines from "../BreedLines";
import SimpleTable from "../../../../../components/common/table/simple/SimpleTable";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

/**
 * Таблица с породами для ПК- версии
 * Колонки: название породы, происхождение
 */
const DesktopBreedsTable = ({breedsInfo}) => {
    const {t} = useTranslation();
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);
    return (
        <SimpleTable>
            <TableTitleTwoCols title={
                {
                    first: t("page.breeds.table.title"),
                    second: t("page.breeds.table.origin"),
                    actions: isAdmin || isModerator ? "действия" : ""
                }
            }/>
            <BreedLines breedsInfo={breedsInfo} needActions={isAdmin || isModerator}/>
        </SimpleTable>
    );
};

export default DesktopBreedsTable;