import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AddElementLink from "../common/ui/buttons/AddElementLink";

/**
 * Backref на предыдущую страницу и кнопка "добавить элемент справочника"
 */
const BackwardLinkAndAddDictIcon = ({
                                        showControlPanel = true,
                                        useBackref = true,
                                        backrefPath = null,
                                        backrefTitle = null,
                                        enableAddingElements = true,
                                        dictionaryBackendName = null
                                    }) => {
    const {t} = useTranslation();

    function getBackrefPath() {
        return backrefPath || '/panel/dictionaries/';
    }

    function getBackrefTitle() {
        return backrefTitle || t('form.common.phrases.backToDictList');
    }

    return (
        <div className={`backward-link-and-add-dict-icon__wrapper ${showControlPanel ? '' : 'hidden'}`}>
            <div className="backward-link">
                {useBackref ?
                    <Link to={getBackrefPath()} className="backref__link">{getBackrefTitle()}</Link> :
                    null
                }
            </div>
            <div className="add-dict-icon">
                {
                    enableAddingElements && dictionaryBackendName !== null ?
                        <AddElementLink goto={`/panel/dictionaries/${dictionaryBackendName}/new`}/> : null
                }
            </div>
        </div>
    );
};

export default BackwardLinkAndAddDictIcon;