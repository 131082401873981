import React from 'react';
import {useNavigate} from "react-router-dom";
import TableLine from "../TableLine";
import TableCell from "../../cells/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

/**
 * Общая структура - строка в таблице состоящая из одной колонки
 * localizedEntity = {id, title}
 */
const TableLineOneColLocalized = ({
                                      localizedEntity,
                                      isEditable,
                                      withActions = false,
                                      navigatePath = null,
                                      deleteFunction = null
                                  }) => {
    const navigate = useNavigate();
    return (
        <TableLine editable={isEditable}>
            <TableCell cellSize={withActions ? '90' : '100'}
                       onClick={() => isEditable && navigatePath !== null ? navigate(navigatePath + localizedEntity.id) : null}>
                <span>{localizedEntity.translation.title}</span>
            </TableCell>
            {
                withActions ?
                    <TableCell cellSize='10'
                               onClick={() => deleteFunction === null ?
                                   null :
                                   deleteFunction(localizedEntity.id, localizedEntity.translation.title)}>
                        <div className="simple-link">
                            <FontAwesomeIcon icon={faTrash} size={"xl"}/>
                        </div>
                    </TableCell> : null
            }
        </TableLine>
    );
};

export default TableLineOneColLocalized;