import React from 'react';

/**
 * Компонент - характеристика породы.
 * Состоит из: Название, с, по, единица измерения
 * */
const BreedCharacteristic = ({title, from, to, measure}) => {
    return (
        <div className="breed-characteristics__item">
            <div className="breed-characteristics__item-header">{title}</div>
            <div className="breed-characteristics__item-body">
                {from} - {to} {measure}
            </div>
        </div>
    );
};

export default BreedCharacteristic;