import React from 'react';

/**
 * Обертка - Лого питомца на верхнем меню или кнопка "Добавить"
 */
const PetIcon = ({children}) => {
    return (
        <div className="pet_icon">
            <div className="circle">
                {children}
            </div>
        </div>
    );
};

export default PetIcon;