import React from 'react';

/**
 * Кнопка для удаления элементов
 */
const RemoveButton = ({...props}) => {
    return (
        <div {...props} className="remove-btn">
        </div>
    );
};

export default RemoveButton;