import React from 'react';
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {hasValue} from "../../../../../app/helper/commonHelper";
import {useParams} from "react-router-dom";

/**
 * Блок с информацией по идентификации питомца
 */
const IdentificationBlock = ({pet}) => {
    const params = useParams();
    const infoMap = [
        {
            title: 'Номер чипа',
            value: pet.identificationInfo?.chipNumber,
            hasValue: hasValue(pet.identificationInfo?.chipNumber),
            onClickPath: `/pets/${params.id}/chip-number`
        },
        {
            title: 'Номер татуировки',
            value: pet.identificationInfo?.tattooNumber,
            hasValue: hasValue(pet.identificationInfo?.tattooNumber),
            onClickPath: `/pets/${params.id}/tattoo-number`
        },
        {
            title: 'Отличительные особенности',
            value: pet.identificationInfo?.distinctiveFeatures,
            hasValue: hasValue(pet.identificationInfo?.distinctiveFeatures),
            onClickPath: `/pets/${params.id}/distinctive-features`
        }
    ];

    return (
        <InformationBlock title='Идентификация' infoMap={infoMap}/>
    );
};

export default IdentificationBlock;