import React from 'react';

/**
 * Светло-коричневая кнопка.
 */
const LightBrownButton = ({children, ...props}) => {
    return (
        <div {...props} className="light-brown-btn">
            <span className="light-brown-btn__label">{children}</span>
        </div>
    );
};

export default LightBrownButton;