import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useNavigate} from "react-router-dom";
import UserService from "../../../../../service/UserService";
import FormError from "../../parts/inputs/wrappers/FormError";
import {useDispatch, useSelector} from "react-redux";
import {hideModal} from "../../../../../app/store/modalReducer";
import {loginUser} from "../../../../../app/store/userReducer";
import Spinner from "../../../Spinner";
import {ADMIN, MODERATOR} from "../../../../../app/const/userRoles";
import {setBurgerActive} from "../../../../../app/store/burgerReducer";
import ForgotPasswordFormItem from "./ForgotPasswordFormItem";
import TextInputFormItem from "../../parts/inputs/wrappers/TextInputFormItem";
import {EMAIL, PASSWORD} from "../../helper/commonFormConstants";
import {isFieldFilled} from "../../helper/formHelper";
import PasswordFormItem from "../../parts/inputs/wrappers/PasswordFormItem";
import SubmitButton from "../../../ui/buttons/SubmitButton";
import Form from "../../abstract/Form";

//Форма авторизации
const LoginForm = ({redirectOnMainPage = false}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isBurgerActive = useSelector(state => state.burgerReducer.isBurgerActive);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setIsLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email(t("form.common.errors.emailIncorrectFormat"))
            .required(t("form.common.errors.fieldRequired")),
        password: yup.string().required(t("form.common.errors.fieldRequired"))
    })

    const {register, handleSubmit, formState: {errors}, getValues} = useForm({
        resolver: yupResolver(schema),
    });

    function submitForm(formData) {
        setIsLoading(true)

        const response = UserService.loginUser(formData);
        response.then((response) => {
            setError(false);
            const userId = response.data.data.user.id;
            const token = response.headers.authorization;
            const firstName = response.data.data.user.firstName;
            const lastName = response.data.data.user.lastName;
            const email = response.data.data.user.email;
            const photo = response.data.data.user.photo;
            const roles = response.data.data.user.roles;
            const activePet = response.data.data.user.activePet;
            const pets = response.data.data.user.pets;
            const refreshToken = response.data.data.refreshToken;

            dispatch(loginUser(
                {
                    id: userId,
                    firstName: firstName,
                    lastName: lastName,
                    photo: photo,
                    email: email,
                    token: token,
                    refreshToken: refreshToken,
                    isModerator: roles.includes(MODERATOR),
                    isAdmin: roles.includes(ADMIN),
                    activePet: activePet,
                    pets: pets
                }))
            dispatch(hideModal());
            if (isBurgerActive) {
                dispatch(setBurgerActive(false));
            }
            if (redirectOnMainPage) {
                navigate("/")
            }
        }, (error) => {
            const errorMsg = error.response?.data?.messages?.ERROR[0];
            setError(true)
            setErrorMessage(errorMsg ? errorMsg : error.message)
            setIsLoading(false)
        });
    }

    return (
        <Form submitForm={submitForm} handleSubmit={handleSubmit} customClassName='form login-form'>
            {/*Имейл*/}
            <TextInputFormItem errors={errors} fieldName={EMAIL} placeholder={t("form.login.email")}
                               hasValue={isFieldFilled(getValues, EMAIL)} register={register} tabIndex='1'/>

            {/*Пароль*/}
            <PasswordFormItem fieldName={PASSWORD} register={register}
                              placeholder={t("form.login.password")} tabIndex='2'
                              autocomplete="current-password" errors={errors}/>
            {error ? <FormError title={errorMessage}/> : null}
            <ForgotPasswordFormItem/>

            {loading ? <Spinner/> : <SubmitButton label={t("button.login")}/>}
        </Form>
    );
};

export default LoginForm;