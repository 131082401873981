import React from 'react';

/**
 * Заголовок таблицы справочника стран
 */
const CountriesTableTitle = ({title}) => {
    return (
        <div className="table-line table-title">
            <div className="table-line__cell cell-40">
                <span className="table-line__description">{title.name}</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">{title.alpha2}</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">{title.alpha3}</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">{title.isoCode}</span>
            </div>
            <div className="table-line__cell cell-20">
                <span className="table-line__description">{title.continent}</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">{title.actions}</span>
            </div>
        </div>
    );
};

export default CountriesTableTitle;