import React from 'react';

/**
 * Вкладка с настройками пользователя
 */
const UserSettingsTab = () => {
    return (
        <div>
            Настройки
        </div>
    );
};

export default UserSettingsTab;