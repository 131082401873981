import React from 'react';

/**
 * Оранжевая кнопка
 */
const OrangeButtonFilled = ({children, ...props}) => {
    return (
        <div {...props} className="orange-btn">
            <span className="orange-btn__label">{children}</span>
        </div>
    );
};

export default OrangeButtonFilled;