/**
 * Константы для формы питомца
 */
export const NEW_PET_STEP_ONE_PATH = '/pets/new-step-one'
export const NEW_PET_STEP_TWO_PATH = '/pets/new-step-two'
export const NEW_PET_STEP_THREE_PATH = '/pets/new-step-three'
export const NEW_PET_STEP_FOUR_PATH = '/pets/new-step-four'
export const NEW_PET_STEP_FIVE_PATH = '/pets/new-step-five'
export const PET_HOME_NAME = "petHomeName";
export const PET_WEIGHT = "petWeight";
export const PET_BIRTH_DATE = "petBirthDate";
export const PET_ANIMAL_KIND = "petAnimalKind";
export const PET_BREED = "petBreed";
export const PET_GENDER = "petGender";
export const IS_PET_NEUTER = "isPetNeuter";
export const NUTRITION_TYPE = "nutritionType";
export const LIVING_ENVIRONMENT = "livingEnvironment";
export const LIVING_CONDITIONS = "livingConditions";
export const CHIP_NUMBER = "chipNumber";
export const TATTOO_NUMBER = "tattooNumber";
export const DISTINCTIVE_FEATURES = "distinctiveFeatures";
export const DEWORMING_DATE = "dewormingDate";
export const FLEA_AND_TICK_TREATMENT_DATE = "fleaAndTickTreatmentDate";
export const RABIES_VACCINATION_DATE = "rabiesVaccinationDate";
export const COMPLEX_VACCINATION_DATE = "complexVaccinationDate";
export const PET_FORM_VALIDATED_STEPS = "petFormValidatedSteps";

export const addPetSteps = [
    {number: 1, title: 'Знакомство', path: NEW_PET_STEP_ONE_PATH},
    {number: 2, title: 'Дополнительная информация', path: NEW_PET_STEP_TWO_PATH},
    {number: 3, title: 'Условия содержания', path: NEW_PET_STEP_THREE_PATH},
    {number: 4, title: 'Идентификация', path: NEW_PET_STEP_FOUR_PATH},
    {number: 5, title: 'Вакцинации и обработки', path: '/pets/new-step-five' },
]