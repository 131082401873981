import React from 'react';
import FormItem from "./FormItem";
import ImageUploading from "react-images-uploading";
import {useTranslation} from "react-i18next";

/**
 * Фото на форме
 */
const FormImage = ({images, onChange, maxNumber}) => {
    const {t} = useTranslation();
    return (
        <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={["jpg"]}>
            {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  isDragging,
                  dragProps
              }) => (
                <FormItem>
                    <div className="breed-photo__wrapper">
                        {imageList.length === 0 ?
                            <div className="breed-photo__add-button"
                                 style={isDragging ? {color: "red"} : null}
                                 onClick={onImageUpload}
                                 {...dragProps}>
                                <span>{t("form.common.fields.clickHereOrDrag")}</span>
                            </div>
                            : imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <div className="close_btn close-image" onClick={() => onImageRemove(index)}>
                                        <span></span>
                                    </div>
                                    <img src={image.data_url} className="plain-img" alt=""/>
                                </div>
                            ))
                        }
                    </div>
                </FormItem>)}
        </ImageUploading>
    );
};

export default FormImage;