import React, {useEffect, useState} from 'react';
import {getActivePetForUpdatePetInfo, petNameFormSchema} from "../../../newpet/helpers/petHelper";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import {PET_HOME_NAME} from "../../../newpet/helpers/petFormConstants";
import PetForm from "../../../newpet/components/forms/PetForm";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import PetService from "../../../newpet/api/PetService";
import {updatePetName} from "../../../../../app/store/userReducer";
import TextInputFormItem from "../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";

/**
 * Работа с кличкой питомца
 */
const PetName = () => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        resolver: yupResolver(petNameFormSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage("");
        setNameErrorMessage("");
    }

    function isButtonActive() {
        return activePet?.name !== watch(PET_HOME_NAME) && watch(PET_HOME_NAME) !== ''
    }

    function init() {
        setValue(PET_HOME_NAME, activePet.name);
    }

    function hasNameValue() {
        return getValues(PET_HOME_NAME) !== ''
    }

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetName(activePet.id, data.petHomeName, token);
        response.then((resp) => {
            dispatch(updatePetName({
                petId: activePet.id,
                name: resp.data.data
            }))
        }, (error) => {
            setNameErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title="Кличка питомца" backTo={`/pets/${params.id}`}
                                     content="Здесь Вы можете отредактировать кличку питомца"/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <TextInputFormItem errors={errors} fieldName={PET_HOME_NAME} placeholder='Его зовут'
                                   register={register} hasValue={hasNameValue} tabIndex='1'/>
                <FormError title={nameErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetName;