import React from 'react';

/**
 * Компонент ошибки формы
 * */
const FormError = ({title}) => {
    if (title === null || title === '') {
        return null;
    }
    return (
        <div className="form__error">
            <span>{title}</span>
        </div>
    );
};

export default FormError;