import React from 'react';

/**
 * Ячейка строки таблицы
 * cellSize - сколько ячейка занимает места в процентах от 100 (влияет только на имя класса)
 */
const TableCell = ({cellSize, additionalStyle = '', children, ...props}) => {
    const customClassName = `table-line__cell cell-${cellSize} ${additionalStyle}`;

    return (
        <div className={customClassName} {...props}>
            {children}
        </div>
    );
};

export default TableCell;