import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {useNavigate} from "react-router";

/**
 * Элемент таблицы - добавить новый элемент
 */
const AddElement = ({title, onClickPath}) => {
    const navigate = useNavigate();
    return (
        <div className="add-element__content" onClick={() => onClickPath === null ? null : navigate(onClickPath)}>
            <FontAwesomeIcon icon={faPlus} className="add-element__content-plus"/>
            <span>{title}</span>
        </div>
    );
};

export default AddElement;