//Значение по умолчанию (используется в селектбоксах)
export const DEFAULT = 'default';

export const EMPTY = '';
export const CAT = 'CAT';
export const DOG = 'DOG';
export const availableAnimalKinds = [CAT, DOG]

export function getLocalizedAnimalKinds(t) {
    return [
        {value: CAT, name: t("animalKinds.cat")},
        {value: DOG, name: t("animalKinds.dog")}
    ];
}
