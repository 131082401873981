import React from 'react';
import {CHECKBOX_CLASS, ERROR_CLASS} from "../../../../../../utils/stringutils";

/**
 * Чекбокс
 */
const CheckboxFormItem = ({fieldName, register, title, tabIndex = null, errors}) => {
    return (
        <div className="form-item">
            <div className={errors[fieldName] ? `${CHECKBOX_CLASS} ${ERROR_CLASS}` : `${CHECKBOX_CLASS}`}>
                <input type="checkbox"
                       className="checkbox__input"
                       id={fieldName}
                       {...register(fieldName)}
                       tabIndex={tabIndex}/>
                <label htmlFor={fieldName} className="checkbox__label">
                    <span>{title}</span>
                </label>
            </div>
        </div>
    );
};

export default CheckboxFormItem;