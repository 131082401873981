import * as yup from "yup";
import {validateNameRegEx} from "../../../../utils/stringutils";
import {
    COUNTRY_ALPHA2_CODE,
    COUNTRY_ALPHA3_CODE,
    COUNTRY_CONTINENT_ID,
    COUNTRY_ID,
    COUNTRY_ISO_CODE,
    COUNTRY_TRANSLATION_ID,
    COUNTRY_TRANSLATION_TITLE
} from "./geoFormsConstants";

/**
 * Валидация формы со странами
 */
export function formCountrySchema(t) {
    return yup.object().shape({
        countryTitle: yup.string()
            .required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        countryAlpha2Code: yup.string()
            .length(2, t("form.common.errors.exact2symbols"))
            .required(t("form.common.errors.fieldRequired")),
        countryAlpha3Code: yup.string()
            .length(3, t("form.common.errors.exact3symbols"))
            .required(t("form.common.errors.fieldRequired")),
        countryIsoCode: yup.string()
            .required(t("form.common.errors.fieldRequired"))
    });
}

/**
 * Заполнение дто по стране данными перед отправкой на бэк
 */
export function formCountryDto(data, language) {
    return {
        id: data[COUNTRY_ID],
        alpha2Code: data[COUNTRY_ALPHA2_CODE],
        alpha3Code: data[COUNTRY_ALPHA3_CODE],
        isoCode: data[COUNTRY_ISO_CODE],
        continent: {
            id: sessionStorage.getItem(COUNTRY_CONTINENT_ID)
        },
        translation: {
            id: data[COUNTRY_TRANSLATION_ID],
            title: data[COUNTRY_TRANSLATION_TITLE],
            locale: language,
        }
    };
}