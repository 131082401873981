import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import config from "../../../app.config.json";

/**
 * Название породы животного
 * вариант со стилями в зависимости от того есть ли фото породы
 * + админу и модератору доступно редактирование
 */
const BreedTitle = ({hasImage, breedId, title}) => {
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);
    return (
        <div className={hasImage? "section-title breed" : "section-title breed without-image"}>
            {isAdmin || isModerator ?
                <div className="greetings _editable">
                    <h1>{title}</h1>
                    <Link to={`/breeds/${breedId}/edit`}>
                        <img
                            src={hasImage ? config.imagesPath.icons + 'edit.png' : config.imagesPath.icons + 'edit_dark.png'}
                            alt=""/>
                    </Link>
                </div>
                : <div className="greetings">
                    <h1>{title}</h1>
                </div>
            }
        </div>
    );
};

export default BreedTitle;