import React, {useState} from 'react';
import OrangeButtonFilled from "../../../ui/buttons/OrangeButtonFilled";
import LoginFormTitle from "../../../form/completeforms/loginform/LoginFormTitle";
import LoginForm from "../../../form/completeforms/loginform/LoginForm";
import UserMenu from "./UserMenu";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {showModal} from "../../../../../app/store/modalReducer";

/**
 * Компонент, объединяющий кнопку входа и меню авторизованного пользователя
 */
const LoginButtonWithAuthUserMenu = () => {
    const [showContent, setShowContent] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    const firstName = useSelector(state => state.userReducer.firstName);

    function showLoginForm() {
        dispatch(showModal({
            popup: {
                title: <LoginFormTitle/>,
                body: <LoginForm/>
            }
        }))
    }

    return (
        <div className="login-button-with-auth-user-menu">
            <OrangeButtonFilled
                onClick={() => {
                    if (!isUserAuth) {
                        showLoginForm()
                    } else {
                        setShowContent(true);
                    }
                }}>
                {isUserAuth ? firstName : t("button.login")}
            </OrangeButtonFilled>
            <UserMenu showContent={showContent} setShowContent={setShowContent}/>
        </div>
    );
};

export default LoginButtonWithAuthUserMenu;