import React from 'react';
import LoginButtonWithAuthUserMenu from "./LoginButtonWithAuthUserMenu";

/**
 * Блок с кнопкой входа и в дальнейшем инфо о пользователе
 * в меню сверху
 */
const LoginSection = () => {
    return (
        <div className="login-section">
            {/*<LanguageSelector/>*/}
            <LoginButtonWithAuthUserMenu/>
        </div>
    );
};

export default LoginSection;