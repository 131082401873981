import React from 'react';
import SmallTitleByCenter from "../../../../components/common/ui/text/SmallTitleByCenter";
import {BORROWED_OBJECT, LINK_DESCRIPTION, SOURCE_LINK, SOURCES} from "../../helpers/breedFormConstants";
import FormItem from "../../../../components/common/form/parts/inputs/wrappers/FormItem";
import HiddenInput from "../../../../components/common/form/parts/inputs/HiddenInput";
import {ACTION_ADD, CUSTOM_INPUT_CLASS} from "../../../../components/common/form/helper/formConstants";
import {ERROR_CLASS} from "../../../../utils/stringutils";
import {storeArrayElement} from "../../../../components/common/form/helper/formHelper";
import RemoveButton from "../../../../components/common/ui/buttons/RemoveButton";
import {addSourceLink, removeSourceLink} from "../../helpers/breedHelper";
import DarkGreenButtonByCenter from "../../../../components/common/ui/buttons/DarkGreenButtonByCenter";
import {useTranslation} from "react-i18next";

/**
 * Динамически формируемый элемент формы "Заимствованная информация" (источники информации)
 */
const BreedSourceLinksFormElement = ({
                                         sourceIds,
                                         sourceLinks,
                                         setSourceLinks,
                                         register,
                                         errors,
                                         action,
                                         getValues,
                                         setValue
                                     }) => {
    const {t} = useTranslation();

    return (
        <>
            <SmallTitleByCenter>{t("form.newBreed.sources")}</SmallTitleByCenter>
            {sourceLinks.map((el, index) => {
                const fieldName = `${SOURCES}[${index}]`;
                return (
                    <FormItem classes='form-item group' key={fieldName}>
                        <HiddenInput fieldName={`${fieldName}.id`} value={el.id} register={register}/>
                        <input type="text"
                               className={errors[SOURCES]?.[index]?.borrowedObject ? `${CUSTOM_INPUT_CLASS} ${ERROR_CLASS}` : `${CUSTOM_INPUT_CLASS}`}
                               name={`${fieldName}.${BORROWED_OBJECT}`}
                               placeholder={t("form.newBreed.borrowed")}
                               {...register(`${fieldName}.${BORROWED_OBJECT}`)}
                               onBlur={(e) => action === ACTION_ADD ?
                                   storeArrayElement(el.id, SOURCES, BORROWED_OBJECT, e.target.value)
                                   : null}/>
                        <input type="text"
                               className={errors[SOURCES]?.[index]?.sourceLink ? `${CUSTOM_INPUT_CLASS} ${ERROR_CLASS}` : `${CUSTOM_INPUT_CLASS}`}
                               name={`${fieldName}.${SOURCE_LINK}`}
                               placeholder='https://'
                               {...register(`${fieldName}.${SOURCE_LINK}`)}
                               onBlur={(e) => action === ACTION_ADD ?
                                   storeArrayElement(el.id, SOURCES, SOURCE_LINK, e.target.value)
                                   : null}/>
                        <input type="text"
                               className={errors[SOURCES]?.[index]?.linkDescription ? `${CUSTOM_INPUT_CLASS} ${ERROR_CLASS}` : `${CUSTOM_INPUT_CLASS}`}
                               name={`${fieldName}.${LINK_DESCRIPTION}`}
                               placeholder={t("form.common.fields.description")}
                               {...register(`${fieldName}.${LINK_DESCRIPTION}`)}
                               onBlur={(e) => action === ACTION_ADD ?
                                   storeArrayElement(el.id, SOURCES, LINK_DESCRIPTION, e.target.value)
                                   : null}/>
                        <RemoveButton
                            onClick={() => removeSourceLink(index, getValues, setValue, setSourceLinks, action === ACTION_ADD)}/>
                    </FormItem>
                );
            })}

            <DarkGreenButtonByCenter
                onClick={() => addSourceLink(sourceIds, sourceLinks, setSourceLinks)}>
                {t("button.add")}
            </DarkGreenButtonByCenter>
        </>
    );
};

export default BreedSourceLinksFormElement;