import React from 'react';

/**
 * Компонент для сортировки записей по критериям
 * */
const SortingSelect = ({options, value, defaultValue, onChangeFunction = null,
                           fieldName = null, onBlurFunction = null, disabled = false}) => {
    return (
        <div className="search-block">
            <select className="search-block__select"
                    disabled={disabled}
                    value={value}
                    onChange={event => onChangeFunction === null ? null : onChangeFunction(event.target.value)}
                    onBlur={(e) => onBlurFunction === null ? null : onBlurFunction(fieldName, e.target.value)}>
                <option disabled value="default">{defaultValue}</option>
                {options?.map(option =>
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                )}
            </select>
        </div>
    );
};

export default SortingSelect;