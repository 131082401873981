import React, {useEffect} from 'react';
import {useWindowSize} from "./hooks/useWindowSize";
import {useDispatch, useSelector} from "react-redux";
import {hideModal} from "../../app/store/modalReducer";
import {setMobile} from "../../app/store/isMobileReducer";
import {cutLongTexts} from "../../utils/stringutils";

/**
 * Главный контейнер приложения
 * */
const MainContainer = ({children}) => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);

    function setIsMobile() {
        if (width > 1080 && isMobile === true) {
            dispatch(setMobile(false))
        } else if (width !== 0 && width <= 1080 && isMobile === false) {
            dispatch(setMobile(true))
        }
    }

    /**
     * Производим манипуляции с текстом
     */
    function doActionsWithText() {
        cutLongTexts(isMobile);
    }

    /**
     * При перезагрузке страницы закрывать всплывашку
     */
    function closePopup() {
        dispatch(hideModal())
    }

    useEffect(() => {
        setIsMobile();
        doActionsWithText();
        closePopup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    return (
        <div className="container">
            {children}
        </div>
    );
};

export default MainContainer;