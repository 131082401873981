import React from 'react';

/**
 * Компонент поискового инпута
 */
const SearchInput = ({placeholder, value, onChange}) => {
    return (
        <div className="form-item search-form">
            <input type="text"
                   className="form__input"
                   placeholder={placeholder}
                   value={value}
                   onChange={e => onChange(e.target.value)}/>
        </div>
    );
};

export default SearchInput;