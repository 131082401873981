import React from 'react';
import PetIcon from "./PetIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons/faEllipsis";
import {useDispatch} from "react-redux";
import {showModal} from "../../../../../app/store/modalReducer";
import UserPetsTable from "../../../table/userpets/UserPetsTable";

/**
 * Элемент верхнего меню - кнопка "другие питомцы"
 */
const TopMenuOtherPetsIcon = () => {
    const dispatch = useDispatch();

    function showPopup() {
        dispatch(showModal({
            popup: {
                title: 'Питомцы',
                body: <UserPetsTable/>
            }
        }))
    }

    return (
        <PetIcon>
            <FontAwesomeIcon icon={faEllipsis} className="circle__ellipsis"
                             onClick={() => showPopup()}/>
        </PetIcon>
    );
};

export default TopMenuOtherPetsIcon;