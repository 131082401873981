import React from 'react';

/**
 * Компонент ссылки для мобильной версии
 */
const MobileSectionLink = ({iconBefore, title, fitToCenter = false, onClickAction = null}) => {
    return (
        <div className={fitToCenter ? "mobile-section__link center" : "mobile-section__link"}>
            {iconBefore === null ? null : iconBefore}
            <span onClick={() => onClickAction === null ? null : onClickAction()}>
                {title}
            </span>
        </div>
    );
};

export default MobileSectionLink;