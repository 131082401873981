import React from 'react';
import PanelMenu from "./menu/PanelMenu";
import PanelPageContent from "./menu/PanelPageContent";

/**
 * Контент страниц админ панели
 */
const PanelContent = ({title, children}) => {
    return (
        <div className="panel-content">
            <div className="content-flex-wrapper">
                <div className="basic-information-block">
                    <PanelMenu/>
                    <PanelPageContent title={title}
                                      children={children}/>
                </div>
            </div>
        </div>
    );
};

export default PanelContent;