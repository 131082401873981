import React from 'react';
import BreedLine from "./BreedLine";

const BreedLines = ({breedsInfo, needActions = false}) => {
    return (
        <>
            {breedsInfo.map(breedInfo =>
                <BreedLine breedInfo={breedInfo} withActions={needActions} key={breedInfo.breedId}/>
            )}
        </>
    );
};

export default BreedLines;