import React from 'react';
import {TATTOO_NUMBER} from "../../../newpet/helpers/petFormConstants";
import {useTranslation} from "react-i18next";
import TextInputFormItem from "../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";

/**
 * Компонент формы "номер татуировки питомца"
 */
const PetTattooNumberItem = ({errors, register, hasValue, tabIndex, onBlurFunction = null}) => {
    const {t} = useTranslation();
    return (
        <TextInputFormItem errors={errors} register={register} fieldName={TATTOO_NUMBER}
                           placeholder={t('page.pet.tatooNumber')} tabIndex={tabIndex} hasValue={hasValue}
                           onBlurFunction={onBlurFunction}/>
    );
};

export default PetTattooNumberItem;