import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isMobile: window.innerWidth <= 1080,
};

const isMobileSlice = createSlice({
    name: 'isMobile',
    initialState: initialState,
    reducers: {
        setMobile(state, action) {
            state.isMobile = action.payload
        }
    }
})

export default isMobileSlice.reducer
export const {setMobile} = isMobileSlice.actions
