import React from 'react';
import RadioButtons from "../../../../../components/common/form/parts/inputs/RadioButtons";
import {LIVING_CONDITIONS} from "../../../newpet/helpers/petFormConstants";
import {useTranslation} from "react-i18next";

/**
 * Компонент формы "Условия проживания питомца"
 */
const PetLivingConditionsFormItem = ({
                                         register, errors, toStore = false, hookFormSetValue,
                                         needLabel = true
                                     }) => {
    const {t} = useTranslation();
    const possibleLivingConditionVars = [
        {value: "INDOOR", title: t('enums.livingCondition.indoor')},
        {value: "OUTDOOR", title: t('enums.livingCondition.outdoor')}
    ];
    return (
        <RadioButtons register={register} fieldName={LIVING_CONDITIONS}
                      label={needLabel ? t('page.pet.newPet.petLivingCondition') : ''}
                      values={possibleLivingConditionVars} toStore={toStore} hookFormSetValue={hookFormSetValue}
                      errors={errors}/>
    );
};

export default PetLivingConditionsFormItem;