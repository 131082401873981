import React from 'react';
import InformationBlockHeader from "./InformationBlockHeader";
import InformationBlockItem from "./InformationBlockItem";
import AddElement from "../../../../../pages/pets/viewpet/components/AddElement";
import InformationBlockContent from "./InformationBlockContent";
import Spinner from "../../../Spinner";

/**
 * Информационный блок, состоящий из заголовка и произвольного количества
 * строк - элементов
 */
const InformationBlock = ({title, infoMap}) => {
    return infoMap === undefined ?
        <Spinner/> :
        <div className='information-block-content'>
            <InformationBlockHeader title={title}/>
            <InformationBlockContent>
                {infoMap.sort((a, b) => b.hasValue - a.hasValue)
                    .map(el => el.hasValue ?
                        <InformationBlockItem title={el.title} info={el.value} key={el.title} disabled={el.disabled}
                                              onClickPath={el.onClickPath}/> :
                        <AddElement title={el.title} key={el.title} onClickPath={el.onClickPath}/>)
                }
            </InformationBlockContent>
        </div>
};

export default InformationBlock;