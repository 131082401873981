import React from 'react';
import {ERROR_CLASS, INPUT_CLASS} from "../../../../../../utils/stringutils";
import FormError from "./FormError";
import FormItem from "./FormItem";
import NumberInput from "../NumberInput";

/**
 * Компонент формы "Текстовый инпут для ввода цифр"
 * надстройка над компонентом NumberInput
 *
 * Помимо самого инпута снизу выводит текст ошибки если она есть.
 */
const NumberInputFormItem = ({
                                 fieldName,
                                 register,
                                 placeholder,
                                 onBlurFunction = null,
                                 tabIndex,
                                 hasValue = false,
                                 dispatchFunction = null,
                                 min = 0,
                                 step = 1,
                                 errors
                             }) => {
    return (
        <FormItem>
            <NumberInput
                classes={errors[fieldName] ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                fieldName={fieldName} register={register} placeholder={placeholder}
                onBlurFunction={onBlurFunction} tabIndex={tabIndex} hasValue={hasValue}
                dispatchFunction={dispatchFunction} min={min} step={step}/>
            {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default NumberInputFormItem;