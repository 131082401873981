import React from 'react';
import {useTranslation} from "react-i18next";
import {hideModal} from "../../app/store/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import TitleByCenter from "./ui/text/TitleByCenter";
import OrangeButtonFilled from "./ui/buttons/OrangeButtonFilled";
import {useLocation, useNavigate} from "react-router-dom";
import {setBurgerActive} from "../../app/store/burgerReducer";

/**
 * Компонент, выводящий сообщение об успешном действии
 */
const SuccessActionMessage = ({header, message}) => {
    const dispatch = useDispatch();
    const isBurgerActive = useSelector(state => state.burgerReducer.isBurgerActive);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    function doClose() {
        dispatch(hideModal())
        if (isBurgerActive) {
            dispatch(setBurgerActive(false));
        }
        if ('/' !== location.pathname) {
            navigate("/");
        }
    }

    return (
        <div className="success-registration">
            <TitleByCenter>{header}</TitleByCenter>
            <span className="success-registration__content">
                {message}
            </span>
            <OrangeButtonFilled onClick={() => doClose()}>
                {t("button.close")}
            </OrangeButtonFilled>
        </div>
    );
};

export default SuccessActionMessage;