import React from 'react';
import Spinner from "../../../../../components/common/Spinner";
import InformationBlocks from "../../../../../components/common/ui/text/informationblock/InformationBlocks";
import OtherInfoBlock from "../infoblocks/OtherInfoBlock";

/**
 * Вкладка "Расширенная информация"
 */
const PetExtendedInfo = ({pet}) => {
    if (pet === undefined) {
        return <Spinner/>;
    }

    return (
        <InformationBlocks>
            <OtherInfoBlock pet={pet}/>
        </InformationBlocks>
    );
};

export default PetExtendedInfo;