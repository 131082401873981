import React, {useEffect, useRef, useState} from 'react';
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {LABEL_CLASSNAME_WITHOUT_VALUE} from "../../../helper/commonFormConstants";
import {movePlaceholderDown, movePlaceholderUp, storeItem} from "../../../helper/formHelper";
import {useFilteredItemsForInputWithOptions} from "../../../hooks/useFilteredItemsForInputWithOptions";
import FormItem from "./FormItem";

/**
 * Компонент текстового инпута с выпадающим списком
 */
const TextInputWithOptionsFormItem = ({
                                          fieldName, register, options, placeholder, tabIndex,
                                          initialId = 0, isNeedToStore = true
                                      }) => {
    const inputRef = useRef('');
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE);
    const [emptyResult, setEmptyResult] = useState(false)
    // значение, которое отображается в инпуте
    const [inputValue, setInputValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    // фильтруем элементы
    const filteredItems = useFilteredItemsForInputWithOptions(options, inputRef.current.value, setEmptyResult)

    useEffect(() => {
        setInitialValueIntoInput();
    }, [options]);

    /**
     * Просетить начальное значение в инпут
     */
    function setInitialValueIntoInput() {
        const initId = Number(initialId)
        if (initId === null || initId === 0 || isEmptyOrNull(options) || options.length === 0) {
            return
        }
        const option = options.find(el => Number(el.id) === initId);
        if (hasValue(option)) {
            setInputValue(option.translation.title);
            inputRef.current.value = option.translation.title;
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события изменения в инпуте
     */
    function handleInputChange(val) {
        setInputValue(val);
        if (!showDropdown) {
            setShowDropdown(true);
        }
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события получения фокуса в инпуте
     */
    function handleInputFocus() {
        if (isEmptyOrNull(inputValue)) {
            setShowDropdown(true)
        }
        movePlaceholder(showDropdown);
    }

    const handleWheel = (event) => {
        const node = dropdownRef.current;
        if (node) {
            const {scrollTop, scrollHeight, clientHeight} = node;
            const atTop = scrollTop === 0;
            const atBottom = scrollTop + clientHeight === scrollHeight;

            if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
                event.preventDefault();
            }
        }
    };

    /**
     * Обновить значение в инпуте
     * optionId === -1 -- признак отсутствия опций для выбора
     */
    function updateSelectedOption(optionId, optionTitle) {
        if (optionId === null || optionId === -1 || isEmptyOrNull(optionTitle)) {
            return
        }
        if (isNeedToStore) {
            storeItem(fieldName, optionId)
        }
        setInputValue(optionTitle);
        setShowDropdown(false);
        movePlaceholder(showDropdown);
    }

    /**
     * Обработка события нажатия на треугольник
     */
    function handleArrowClick() {
        setShowDropdown(!showDropdown);
        movePlaceholder(!showDropdown);
    }

    /**
     * Обработка потери фокуса инпутом.
     */
    function handleOnBlur() {
        movePlaceholder(showDropdown);
    }

    /**
     * Смещать ли плейсхолдер в инпут при потере фокуса полем
     */
    function movePlaceholder(isDropdownOptionsShow) {
        if (!isDropdownOptionsShow && isEmptyOrNull(inputRef.current.value)) {
            movePlaceholderDown(setLabelClass)
        } else {
            movePlaceholderUp(setLabelClass);
        }

    }

    return (
        <FormItem>
            <div className='input-with-options-wrapper'>
                <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
                {/*Инпут*/}
                <input
                    id={fieldName}
                    type="text"
                    className="form__input"
                    {...register(fieldName)}
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleInputFocus}
                    onBlur={handleOnBlur}
                    tabIndex={tabIndex}
                    autoComplete="off"
                />
                {/*Стрелка*/}
                <div className="input-field-arrow__wrapper" onClick={handleArrowClick}>
                    <div className={`input-field-arrow ${showDropdown ? '' : 'collapsed'}`}></div>
                </div>
                {/*выпадающие элементы*/}
                <div className={`dropdown-menu ${showDropdown ? 'show' : ''}`}>
                    <div className="dropdown-menu__content" onWheel={handleWheel} ref={dropdownRef}>
                        {filteredItems.map((option) => (
                            <div key={option.id} className={`dropdown-item ${emptyResult ? 'no-items' : ''}`}
                                 onClick={() => updateSelectedOption(option.id, option.translation.title)}>
                                <span>{option?.translation?.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </FormItem>
    );
};

export default TextInputWithOptionsFormItem;