import React from 'react';
import Content from "../../../../components/common/Content";
import Breadcrumbs from "../../../../components/common/Breadcrumbs";
import PageTitle from "../../../../components/common/PageTitle";
import PetContentWrapper from "./PetContentWrapper";
import StepInfo from "./StepInfo";
import PetFormStepsFactory from "./PetFormStepsFactory";
import {useTranslation} from "react-i18next";

/**
 * Первый шаг добавления нового питомца
 * общая информация
 */
const NewPet = () => {
    const {t} = useTranslation();
    return (
        <Content>
            <Breadcrumbs elements={[
                {page: t("page.pet.newPet.title"), path: ''}
            ]}/>
            <PageTitle title={t("page.pet.newPet.tellAbout")}/>
            <PetContentWrapper>
                <StepInfo/>
                <PetFormStepsFactory/>
            </PetContentWrapper>
        </Content>
    );
};

export default NewPet;