import React from 'react';

/**
 * Контент информационного блока
 */
const InformationBlockContent = ({children}) => {
    return (
        <div className='information-block-content__body'>
            {children}
        </div>
    );
};

export default InformationBlockContent;