import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import FormError from "../../parts/inputs/wrappers/FormError";
import Spinner from "../../../Spinner";
import UserService from "../../../../../service/UserService";
import {isFieldFilled, showSuccessMessagePopup} from "../../helper/formHelper";
import {showModal} from "../../../../../app/store/modalReducer";
import {useDispatch} from "react-redux";
import {EMAIL} from "../../helper/commonFormConstants";
import TextInputFormItem from "../../parts/inputs/wrappers/TextInputFormItem";
import Form from "../../abstract/Form";
import SubmitButton from "../../../ui/buttons/SubmitButton";

/**
 *
 * Форма "Забыл пароль"
 */
const ForgotPasswordForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setIsLoading] = useState(false);

    const schema = yup.object().shape({
        email: yup.string().email(t("form.common.errors.emailIncorrectFormat"))
            .required(t("form.common.errors.fieldRequired"))
    })

    const {register, handleSubmit, formState: {errors}, getValues} = useForm({
        resolver: yupResolver(schema),
    });

    function submitForm(data) {
        setIsLoading(true)

        const response = UserService.forgotPassword(data.email);
        response.then(() => {
            setError(false);
            setIsLoading(false);
            showSuccessMessagePopup(dispatch, showModal,
                t("form.forgotPassword.success.header"),
                t("form.forgotPassword.success.message"));
        }, (error) => {
            setError(true);
            setIsLoading(false);
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
    }

    return (
        <Form submitForm={submitForm} handleSubmit={handleSubmit}>
            <TextInputFormItem errors={errors} fieldName={EMAIL} placeholder={t("form.login.email")}
                               hasValue={isFieldFilled(getValues, EMAIL)} register={register} tabIndex='1'/>

            {error ? <FormError title={errorMessage}/> : null}
            {loading ? <Spinner/> : <SubmitButton label={t("button.restorePassword")}/>}
        </Form>
    );
};

export default ForgotPasswordForm;