import React, {useEffect, useState} from 'react';
import Content from "../../../../../components/common/Content";
import {useParams} from "react-router-dom";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import CircleImage from "../../../../../components/common/photo/CircleImage";
import OrangeButtonByCenter from "../../../../../components/common/ui/buttons/OrangeButtonByCenter";
import {useDispatch, useSelector} from "react-redux";
import PetService from "../../../newpet/api/PetService";
import {setActivePetPhoto, setPetLogo} from "../../../../../app/store/userReducer";
import {hasValue} from "../../../../../app/helper/commonHelper";
import {updateActivePet, userHasPet} from "../../../newpet/helpers/petHelper";
import TableNoResults from "../../../../../components/common/table/TableNoResults";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import Spinner from "../../../../../components/common/Spinner";

/**
 * Работа с фото питомца
 */
const PetPhoto = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState(false);
    const [petPhotoError, setPetPhotoError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const onImageChange = (imageList) => {
        setChanged(true);
        setImages(imageList);
    };

    function savePetImage() {
        if (!changed) {
            return;
        }
        setError(false);
        setIsLoading(true);
        const resp = PetService.savePetImage(activePet.id, images.length === 0 ? null : images[0].file, token)
        resp.then(
            (resp) => {
                const data = resp.data.data;
                dispatch(setPetLogo(data))
                dispatch(setActivePetPhoto(data))
                setIsLoading(false);
                setChanged(false);
            },
            (error) => {
                setError(true);
                setPetPhotoError(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
                setIsLoading(false);
            })


    }

    function init() {
        setImages([{data_url: `data:image/jpeg;base64,${activePet.photo.fileBase64}`, file: null}]);
    }

    function clearErrors() {
        setError(false);
        setErrorMessage("");
        setPetPhotoError("")
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        if (activePet.id === Number(params.id) && hasValue(activePet.photo)) {
            init();
        } else {
            //если не нашли запрашиваемого питомца в списке питомцев
            if (!userHasPet(pets, params.id)) {
                setErrorMessage("Питомец на найден");
                return;
            }
            //если произошла валидная смена питомца, то мы запрашиваем все данные по нему
            updateActivePet(params.id, token, dispatch, setErrorMessage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Content>
            {errorMessage !== "" ? <TableNoResults title={errorMessage}/> :
                <>
                    <UpdatableElementPreview title="Фото питомца" backTo={`/pets/${params.id}`}
                                             content="Фотография помогает персонализировать аккаунт вашего питомца"/>
                    <CircleImage images={images} maxNumber={1} onChange={onImageChange}/>
                    {error ? <FormError title={petPhotoError}/> : null}
                    {isLoading ?
                        <Spinner/> :
                        <OrangeButtonByCenter isActive={changed} onClick={() => savePetImage()}>
                            Сохранить
                        </OrangeButtonByCenter>
                    }
                </>}
        </Content>
    );
};

export default PetPhoto;