import * as yup from "yup";
import {validateNameRegEx} from "../../../utils/stringutils";

/**
 * Валидация формы с именем пользователя
 */
export function userNameFormSchema(t) {
    return yup.object().shape({
        userName: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx)
    });
}

/**
 * Валидация формы с фамилией пользователя
 */
export function userLastnameFormSchema(t) {
    return yup.object().shape({
        lastname: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx)
    });
}