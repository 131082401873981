import axios from "axios";
import {LOCAL} from "../app/const/main";

/**
 * Получить список всех значений справочника в текущем переводе
 */
export async function getAllRecordsInCurrentTranslation(dictionaryNameInController) {
    return await axios.get(`${LOCAL}/${dictionaryNameInController}`);
}

/**
 * Получить все сведения о записи из справочника по Id
 */
export async function getAllRecordDataById(id, dictionaryNameInController, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.get(`${LOCAL}/${dictionaryNameInController}/${id}`, config);
}

/**
 *  Сохранить/обновить сведения о записи в справочнике на конкретном языке
 */
export async function saveRecord(data, dictionaryNameInController, token) {
    const form = new FormData();
    const dtoBlob = new Blob([JSON.stringify(data)], {
        type: 'application/json'
    });

    form.append("dto", dtoBlob)
    return await axios.post(`${LOCAL}/${dictionaryNameInController}/save`, form,
        {
            headers: {
                'Authorization': 'Bearer_' + token
            }
        });
}

/**
 * Удалить запись из справочника по Id
 * (где ID - идентификатор мультиязычной сущности, т.е. удалятся все переводы)
 */
export async function removeRecord(id, dictionaryNameInController, token) {
    const config = {
        headers: {
            'Authorization': 'Bearer_' + token
        }
    }
    return await axios.delete(`${LOCAL}/${dictionaryNameInController}/${id}/delete`, config);
}
