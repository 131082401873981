/**
 * Прикладные методы для работы с датой
 */

/**
 * На вход подается строка вида YYYY-mm-dd(...)
 * На выходе получаем дату в формате dd.mm.YYYY
 */
export function getDatePrettyFormat(uglyDate) {
    if (uglyDate === null || uglyDate === undefined) {
        return '';
    }
    let clearUglyDate = uglyDate.substring(0, 10);
    let dateParts = clearUglyDate.split('-');
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`
}

/**
 * На вход подается ОБЪЕКТ Date
 * На выходе получаем СТРОКУ в виде YYYY-mm-dd
 */
export function getDateFromDateObject(uglyDate) {
    if (uglyDate === null || uglyDate === undefined) {
        return '';
    }
    return uglyDate.getFullYear() + "-" + getMonthNormalized(uglyDate) + "-" + getDayNormalized(uglyDate);
}

function getMonthNormalized(uglyDate) {
    const month = uglyDate.getMonth() + 1;
    return month < 10 ? "0" + month : month;
}

function getDayNormalized(uglyDate) {
    const day = uglyDate.getDate();
    return day < 10 ? "0" + day : day;
}

/**
 * На вход подается строка вида YYYY-mm-dd(...)
 * На выходе получаем чистую дату виду YYYY-mm-dd
 */
export function getClearDate(uglyDate) {
    if (uglyDate === null || uglyDate === undefined) {
        return '';
    }
    return uglyDate.substring(0, 10);
}

/**
 * Получить возраст в красивом формате
 *
 * @param birthDate дата рождения в строковом виде YYYY-mm-dd(...)
 * @return возраст в красивом формате, например X л Y мес
 */
export function getAgePrettyFormat(birthDate) {

    const bDay = new Date(Date.parse(birthDate));
    const currentDate = new Date();
    const yearCount = currentDate.getYear() - bDay.getYear();
    const monthCount = currentDate.getMonth() - bDay.getMonth();

    return yearCount === 0 ? `${monthCount} мес`: `${yearCount} ${getYearLetter(yearCount)} ${monthCount} мес`
}

/**
 * Получить значение "г" или "л" в зависимости от количества лет
 */
function getYearLetter(yearNum) {
    // "г"
    const arr = [1, 2, 3, 4, 21, 22, 23, 24, 31, 32, 33, 34];
    return arr.includes(yearNum) ? "г" : "л";
}