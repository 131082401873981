import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import UserService from "../../../service/UserService";
import Content from "../../../components/common/Content";
import PageTitle from "../../../components/common/PageTitle";
import Spinner from "../../../components/common/Spinner";
import GoToMainPageWithReasonIfError from "../resetpassword/GoToMainPageWithReasonIfError";
import ActivationSuccessMessage from "./ActivationSuccessMessage";

/**
 * Активировать пользователя по коду активации
 */
const ActivateUser = () => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    const navigate = useNavigate();
    const params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        if (checkUserActivationPossibility()) {
            activateUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Проверка: доступна ли вкладка для текущего пользователя
     */
    function checkUserActivationPossibility() {
        if (isUserAuth) {
            navigate("/");
            return false;
        }
        return true;
    }

    /**
     * Активировать пользователя по коду активации
     */
    function activateUser() {
        setIsLoading(true)
        const response = UserService.activateUser(params.code);
        response.then(() => {
            setError(false);
            setIsLoading(false)
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    return (
        <Content>
            <PageTitle title={t("page.userActivation.title")}/>
            {isLoading ? <Spinner/> :
                error ? <GoToMainPageWithReasonIfError errorMessage={errorMessage}/> :
                    <ActivationSuccessMessage message={t("page.userActivation.success.message")}
                                              buttonTitle={t("page.userActivation.success.buttonTitle")}/>}
        </Content>
    );
};

export default ActivateUser;