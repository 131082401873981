import React from 'react';
import Content from "../../components/common/Content";
import {useSelector} from "react-redux";
import {hasValue} from "../../app/helper/commonHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";
import MobileSectionLinks from "../../components/common/header/mobile/MobileSectionLinks";
import MobileSectionLink from "../../components/common/header/mobile/MobileSectionLink";
import MobileLinksBlock from "../../components/common/header/mobile/MobileLinksBlock";
import {useNavigate} from "react-router";
import {getAgePrettyFormat} from "../../app/helper/dateHelper";

/**
 * Контент главной страницы мобильной версии
 * для зарегистрированного пользователя
 */
const MobileIndexContentAuthUser = () => {
    const activePet = useSelector(state => state.userReducer.activePet);
    const navigate = useNavigate();

    function onClickPetProfile() {
        navigate(`/pets/${activePet.id}`);
    }

    if (hasValue(activePet)) {
        return (
            <Content>
                <div className="pet-name-with-age">
                    <span>{activePet.name}</span>
                    <span className="pet-name-with-age__birthdate">{getAgePrettyFormat(activePet.birthDate)}</span>
                </div>
                <div className="circle-photo__wrapper">
                    {hasValue(activePet.photo) ?
                        <div className="circle-image-item">
                            <img src={`data:image/jpeg;base64,${activePet.photo.fileBase64}`}
                                 alt={activePet.name} className="img-circle"/>
                        </div> :
                        <div className="circle-photo__add-button static">
                            <FontAwesomeIcon icon={faCamera} className="fa-camera"/>
                        </div>
                    }
                </div>
                <MobileLinksBlock>
                    <MobileSectionLinks isActive={true} fitToCenter={true}>
                        <MobileSectionLink title='Профиль питомца' onClickAction={onClickPetProfile}/>
                    </MobileSectionLinks>
                </MobileLinksBlock>
            </Content>
        );
    } else {
        return (
            <Content>
                <span>Питомца нет</span>
            </Content>
        );
    }
    /**/
};

export default MobileIndexContentAuthUser;