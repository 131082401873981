import React from 'react';

/**
 * Группа кнопок в линии (пока две)
 */
const ButtonGroup = ({children}) => {
    return (
        <div className="button-group">
            {children}
        </div>
    );
};

export default ButtonGroup;