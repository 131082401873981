import React from 'react';

/**
 * Заголовок для блока ссылок в мобильном меню
 */
const SectionTitle = ({iconBefore = null, isActive, setActive, title}) => {
    function toggleActiveState() {
        return setActive(!isActive);
    }

    return (
        <div className="mobile-section__title" onClick={() => toggleActiveState()}>
            {iconBefore === null ? null : iconBefore}
            <span>{title}</span>
            <span className={isActive ? "arrow active" : "arrow"}>
                    <span></span>
                    <span></span>
                </span>
        </div>
    );
};

export default SectionTitle;