import React from 'react';

/**
 * Один из двух элементов формы образующих диапазон
 */
const FormItem = ({children}) => {
    return (
        <div className="range-item">
            {children}
        </div>
    );
};

export default FormItem;