import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {hideModal} from "../../../../app/store/modalReducer";

/**
 * Компонент модального окна.
 * Окно состоит из двух частей: заголовок и контент
 * При активном модальном окне блокирует скрол body
 */
const ModalWindow = () => {
    const isActive = useSelector(state => state.modalReducer.isActive);
    const popup = useSelector(state => state.modalReducer.popup);
    const dispatch = useDispatch();

    const body = document.querySelector('body');
    const timeout = 400;
    let unlock = true;

    function popupClose(doUnlock = true) {
        if (unlock && rootClasses.indexOf('open') > -1) {
            dispatch(hideModal())
            if (doUnlock) {
                bodyUnlock();
            }
        }
    }

    function bodyLock() {
        // высчитываем ширину скрола - чтобы контент не смещался в сторону в момент открытия/закрытия попапа
        body.classList.add('lock');
        unlock = false;
        //чтобы предотвратить повторное нажатие
        setTimeout(function () {
            unlock = true;
        }, timeout);
    }

    function bodyUnlock() {
        //скролл появляется только когда заканчивается анимация
        setTimeout(function () {
            body.classList.remove('lock');
        }, timeout);

        unlock = false;
        //чтобы предотвратить повторное нажатие
        setTimeout(function () {
            unlock = true;
        }, timeout);
    }
    //закрывать попап при перезагрузке страницы
    if (popup.body.type === undefined && isActive) {
        return null;
    }
    //закрывать попап по Esc
    document.addEventListener('keydown', function (e) {
        if (e.which === 27) {
            const popupActive = document.querySelector('.popup.open');
            popupClose(popupActive);
        }
    });
    const rootClasses = ['popup'];
    if (isActive) {
        rootClasses.push('open');
        bodyLock();
    } else {
        //разблокируем Body если закрыли попап при нажатии на внешней области
        if (document.querySelector('.popup.open') != null) {
            bodyUnlock();
        }
    }

    return (
        <div className={rootClasses.join((' '))}>
            <div className="popup__body" onClick={() => hideModal()}>
                <div className="popup__content" onClick={(e) => e.stopPropagation()}>
                    <div className="close_btn" onClick={() => popupClose()}>
                        <span></span>
                    </div>
                    <div className="popup__title">
                        {popup.title}
                    </div>
                    <div className="popup__text">
                        {popup.body}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalWindow;