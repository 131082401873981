import React from 'react';
import HeaderContainer from "../HeaderContainer";
import Logo from "../Logo";
import TopMenu from "./topmenu/TopMenu";
import LoginSection from "./loginblock/LoginSection";

/**
 * Хэдер для настольной версии
 */
const DesktopHeader = () => {
    return (
        <HeaderContainer>
            <Logo/>
            <TopMenu/>
            <LoginSection/>
        </HeaderContainer>
    );
};

export default DesktopHeader;