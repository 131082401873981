import React from 'react';
import {useNavigate} from "react-router-dom";
import TableLine from "../../../../../components/common/table/simple/line/TableLine";
import TableCell from "../../../../../components/common/table/simple/cells/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";

/**
 * Строка в таблице с породами
 * для мобильного представления
 */
const MobileBreedLine = ({breedInfo, withActions = false, deleteFunction}) => {
    const navigate = useNavigate();
    return (
        <TableLine editable={true}>
            <TableCell cellSize={withActions ? '90' : '100'} onClick={() => navigate('/breeds/' + breedInfo.breedId)}>
                <span>{breedInfo.title}</span>
            </TableCell>
            {
                withActions ?
                    <TableCell cellSize='10' onClick={() => deleteFunction(breedInfo.breedId, breedInfo.title)}>
                        <div className="simple-link">
                            <FontAwesomeIcon icon={faTrash} size={"xl"}/>
                        </div>
                    </TableCell> : null
            }

        </TableLine>
    );

};

export default MobileBreedLine;