export const PET_BASIC_INFO_TAB = 'petBasicInfoTab';
export const PET_EXTENDED_INFO_TAB = 'petExtendedInfoTab';
export const PET_GENDER = {
    'MALE': 'page.pet.gender.male',
    'FEMALE': 'page.pet.gender.female'
};

export const MALE_GENDER = {value: "MALE", title: "Самец"};
export const FEMALE_GENDER = {value: "FEMALE", title: "Самка"};
export const LIVING_CONDITIONS = {
    'INDOOR': 'page.pet.livingConditions.indoor',
    'OUTDOOR': 'page.pet.livingConditions.outdoor'
};
export const LIVING_ENVIRONMENT = {
    'CITY': 'page.pet.livingEnvironment.city',
    'COUNTRYSIDE': 'page.pet.livingEnvironment.countryside'
};
