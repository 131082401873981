import React from 'react';
import TopMenuPetIcon from "../../header/desktop/petblock/TopMenuPetIcon";
import {useNavigate} from "react-router-dom";
import TableLine from "../simple/line/TableLine";
import TableCell from "../simple/cells/TableCell";
import {hideModal} from "../../../../app/store/modalReducer";
import {useDispatch, useSelector} from "react-redux";
import {setBurgerActive} from "../../../../app/store/burgerReducer";

/**
 * Строка таблицы питомцев пользователя
 */
const UserPetLine = ({userPet}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);

    function onClickFunc(petId) {
        dispatch(hideModal())
        if (isMobile) {
            dispatch(setBurgerActive(false));
        }
        navigate('/pets/' + petId)
    }

    return (
        <TableLine editable={true}>
            <TableCell cellSize='25' additionalStyle='pet-logo-in-cell' onClick={() => onClickFunc(userPet.id)}>
                {<TopMenuPetIcon petInfo={userPet}/>}
            </TableCell>
            <TableCell cellSize='75' additionalStyle='pet-details-in-cell' onClick={() => onClickFunc(userPet.id)}>
                <span>{userPet.name}</span>
            </TableCell>
        </TableLine>
    );
};

export default UserPetLine;