/**
 * Константы для общих форм
 */

// название класса для лейбла элемента формы если в поле есть значение
export const LABEL_CLASSNAME_WITH_VALUE = 'focused form-item__label';

// название класса для лейбла элемента формы если значение отсутствует
export const LABEL_CLASSNAME_WITHOUT_VALUE = 'form-item__label';
/**
 * Поле Имейл
 */
export const EMAIL = 'email'
/**
 * Поле пароль
 */
export const PASSWORD = 'password'
/**
 * Повторить пароль
 */
export const REPEAT_PASSWORD = 'repeatPassword'

/**
 * Поле имя
 */
export const FIRST_NAME = 'firstName'
/**
 * Фамилия
 */
export const LAST_NAME = 'lastName'
/**
 * Согласие на обработку данных
 */
export const USER_AGREEMENT = 'agreement'
