import React from 'react';

/**
 * Компонент для удобного выбора одного из двух вариантов
 * first: {value, title}
 * second: {value, title}
 *
 * Данный компонент используется ВНЕ формы
 */
const CustomSelectorTwoVarsInTable = ({
                                          value,
                                          setValue,
                                          first,
                                          second
                                      }) => {
    return (
        <div className="type-selector">
            <div className="selector-options">
                <div className={first.value === value ?
                    "type-selector__item active" :
                    "type-selector__item"}
                     onClick={() => setValue(first.value)}>
                    <span>{first.title}</span>
                </div>
                <div className={second.value === value ?
                    "type-selector__item active" :
                    "type-selector__item"}
                     onClick={() => setValue(second.value)}>
                    <span>{second.title}</span>
                </div>
            </div>
        </div>
    );
};

export default CustomSelectorTwoVarsInTable;