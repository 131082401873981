import React from 'react';
import PanelContent from "../../../components/adminpanel/PanelContent";

/**
 * Дашборд Milkins
 */
const Dashboard = () => {
    return (
        <PanelContent title="Дашборд">
            Здесь будут красивые графики и много цифр
        </PanelContent>
    );
};

export default Dashboard;