import React, {useEffect} from 'react';
import {addPetSteps, CHIP_NUMBER, NEW_PET_STEP_FIVE_PATH, TATTOO_NUMBER} from "../../../helpers/petFormConstants";
import PetForm from "../PetForm";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {
    navigateToTheNextStep,
    petChipNumberFormSchema,
    removeCurrentStepFromValidated,
    stepBack
} from "../../../helpers/petHelper";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import ButtonGroup from "../../../../../../components/common/ui/buttons/ButtonGroup";
import OrangeButtonFilled from "../../../../../../components/common/ui/buttons/OrangeButtonFilled";
import LightBrownButton from "../../../../../../components/common/ui/buttons/LightBrownButton";
import {getStoredItem, storeItem} from "../../../../../../components/common/form/helper/formHelper";
import {STRING} from "../../../../../../components/common/form/helper/formConstants";
import PetChipNumberFormItem from "../../../../common/components/form/PetChipNumberFormItem";
import PetTattooNumberItem from "../../../../common/components/form/PetTattooNumberItem";

/**
 * Второй шаг добавления нового питомца
 */
const PetFormStepFour = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const CURRENT_STEP = 4;
    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petChipNumberFormSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function submitForm() {
        navigateToTheNextStep(CURRENT_STEP, navigate, NEW_PET_STEP_FIVE_PATH);
    }

    function hasChipNumberValue() {
        return getStoredItem(CHIP_NUMBER, STRING) !== 0 && getStoredItem(CHIP_NUMBER, STRING) !== '';
    }

    function hasTattooNumberValue() {
        return getStoredItem(TATTOO_NUMBER, STRING) !== 0 && getStoredItem(TATTOO_NUMBER, STRING) !== '';
    }

    function init() {
        setValue(CHIP_NUMBER, getStoredItem(CHIP_NUMBER, STRING));
        setValue(TATTOO_NUMBER, getStoredItem(TATTOO_NUMBER, STRING));
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <PetChipNumberFormItem errors={errors} register={register} tabIndex='1'
                                   hasValue={hasChipNumberValue()} onBlurFunction={storeItem}/>
            <PetTattooNumberItem errors={errors} register={register} tabIndex='2'
                                 hasValue={hasTattooNumberValue()} onBlurFunction={storeItem}/>
            <ButtonGroup>
                <LightBrownButton onClick={() => stepBack(CURRENT_STEP, addPetSteps, navigate)}>
                    Назад
                </LightBrownButton>
                <OrangeButtonFilled onClick={handleSubmit(submitForm)}>
                    Вперёд
                </OrangeButtonFilled>
            </ButtonGroup>
        </PetForm>
    );
};

export default PetFormStepFour;