import React from 'react';
import PanelContent from "./PanelContent";
import BackwardLinkAndAddDictIcon from "./BackwardLinkAndAddDictIcon";

/**
 * Контент страниц справочников
 */
const DictionaryContent = ({
                               title,
                               showControlPanel = true,
                               useBackref = true,
                               backrefPath = null,
                               backrefTitle = null,
                               enableAddingElements = false,
                               dictionaryBackendName = null,
                               children
                           }) => {
    return (
        <PanelContent title={title}>
            <BackwardLinkAndAddDictIcon showControlPanel={showControlPanel} useBackref={useBackref}
                                        backrefPath={backrefPath} backrefTitle={backrefTitle}
                                        enableAddingElements={enableAddingElements}
                                        dictionaryBackendName={dictionaryBackendName}/>
            {children}
        </PanelContent>
    );
};

export default DictionaryContent;