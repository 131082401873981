import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";

/**
 * Строка из контента информационного блока
 */
const InformationBlockItem = ({title, info, disabled = false, onClickPath = null}) => {
    const navigate = useNavigate();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    return (
        <div className=
                 {disabled ? "information-block-content__body-item disabled" : "information-block-content__body-item"}
             onClick={() => onClickPath === null ? null : navigate(onClickPath)}>
            <div className="item-title">
                <span>{title}</span>
            </div>
            <div className="item-content">
                <span>{info}</span>
            </div>
            {isMobile ? null :
                <div className="item-action">
                    <FontAwesomeIcon icon={faAngleRight} className='fa-angle-right'/>
                </div>}

        </div>
    );
};

export default InformationBlockItem;