import React from 'react';
import FormItem from "./FormItem";

/**
 * Контейнер для элемента формы состоящей из двух полей
 * Зачастую это диапазон
 */
const FormRangeLine = ({children}) => {
    return (
        <FormItem classes="form-item range">
            <div className="range-line">
                {children}
            </div>
        </FormItem>
    );
};

export default FormRangeLine;