import React from 'react';
import {ERROR_CLASS, INPUT_CLASS} from "../../../../../../utils/stringutils";
import FormError from "./FormError";
import FormItem from "./FormItem";
import PasswordInput from "../PasswordInput";

/**
 * Компонент формы "Инпут пароля"
 * надстройка над компонентом PasswordInput
 *
 * Помимо самого инпута снизу выводит текст ошибки если она есть.
 */
const PasswordFormItem = ({fieldName, register, placeholder, tabIndex, autocomplete = null, errors}) => {
    return (
        <FormItem>
            <PasswordInput
                classes={errors[fieldName] ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                fieldName={fieldName} register={register} placeholder={placeholder}
                tabIndex={tabIndex} autocomplete={autocomplete}/>
            {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default PasswordFormItem;