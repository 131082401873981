import axios from "axios";
import {LOCAL} from "../../../../app/const/main";

export default class PetService {
    static async save(dto, token) {
        return await axios.post(`${LOCAL}/pets/save`, dto,
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Получить все сведения о породе по Id
     */
    static async getById(id, token) {
        const config = {
            headers: {
                'Authorization': 'Bearer_' + token
            }
        }
        return await axios.get(`${LOCAL}/pets/${id}/`, config);
    }

    /**
     * Сохранить фотографию питомца
     */
    static async savePetImage(id, image, token) {
        const form = new FormData();
        form.append("image", image);
        return await axios.post(`${LOCAL}/pets/${id}/image`, form,
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить кличку питомца
     */
    static async updatePetName(id, petName, token) {
        return await axios.post(`${LOCAL}/pets/${id}/name?name=${petName}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить породу питомца
     */
    static async updatePetBreed(id, petBreed, token) {
        return await axios.post(`${LOCAL}/pets/${id}/breed?breedId=${petBreed}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить гендер питомца
     */
    static async updatePetGender(id, petGender, token) {
        return await axios.post(`${LOCAL}/pets/${id}/gender?gender=${petGender}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить дату рождения питомца
     */
    static async updatePetBirthDate(id, petBirthDate, token) {
        return await axios.post(`${LOCAL}/pets/${id}/birthdate?birthDate=${petBirthDate}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить номер чипа питомца
     */
    static async updatePetChipNumber(id, chipNumber, token) {
        return await axios.post(`${LOCAL}/pets/${id}/chip-number?chipNumber=${chipNumber}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить номер татуировки питомца
     */
    static async updatePetTattooNumber(id, tattooNumber, token) {
        return await axios.post(`${LOCAL}/pets/${id}/tattoo-number?tattooNumber=${tattooNumber}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить информацию об отличительных особенностях питомца
     */
    static async updatePetDistinctiveFeatures(id, distinctiveFeatures, token) {
        return await axios.post(`${LOCAL}/pets/${id}/distinctive-features?distinctiveFeatures=${distinctiveFeatures}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить сведения о месте обитания питомца
     */
    static async updatePetLivingEnvironment(id, livingEnvironment, token) {
        return await axios.post(`${LOCAL}/pets/${id}/living-environment?livingEnvironment=${livingEnvironment}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }

    /**
     * Обновить сведения об условиях проживания питомца
     */
    static async updatePetLivingConditions(id, livingConditions, token) {
        return await axios.post(`${LOCAL}/pets/${id}/living-conditions?livingConditions=${livingConditions}`, {},
            {
                headers: {
                    'Authorization': 'Bearer_' + token
                }
            });
    }
}