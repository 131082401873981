import React from 'react';
import ImageUploading from "react-images-uploading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";

/**
 * Фото в круге
 */
const CircleImage = ({images, onChange, maxNumber}) => {
    return (
        <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            acceptType={["jpg"]}>
            {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  isDragging,
                  dragProps
              }) => (
                <div className="circle-photo__wrapper">
                    {imageList.length === 0 ?
                        <div className="circle-photo__add-button"
                             style={isDragging ? {color: "red"} : null}
                             onClick={onImageUpload}
                             {...dragProps}>
                            <FontAwesomeIcon icon={faCamera} className="fa-camera"/>
                        </div>
                        : imageList.map((image, index) => (
                            <div key={index} className="circle-image-item">
                                <div className="close_btn close-image" onClick={() => onImageRemove(index)}>
                                    <span></span>
                                </div>
                                <img src={image.data_url} className="img-circle" alt=""/>
                            </div>
                        ))
                    }
                </div>
            )}
        </ImageUploading>
    );
};

export default CircleImage;