import React from 'react';
import SimpleTable from "../simple/SimpleTable";
import {useSelector} from "react-redux";
import UserPetLine from "./UserPetLine";

/**
 * Таблица со списком питомцев пользователя
 */
const UserPetsTable = () => {

    const userPets = useSelector(state => state.userReducer.pets);

    // Создаем копию массива для сортировки
    const petsSorted = userPets.map(userPet => ({...userPet}));
    petsSorted.sort((a,b) => a.name.localeCompare(b.name));

    return (
        <SimpleTable>
            {petsSorted?.map((userPet, index) => {
                    return <UserPetLine key={index} userPet={userPet}/>
                }
            )}
        </SimpleTable>
    );
};

export default UserPetsTable;