import React from 'react';
import {useTranslation} from "react-i18next";
import {clearUserData} from "../../../../../utils/userutils";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

/**
 * Компонент меню зарегистрированного пользователя в шапке.
 * Элементы:
 *   -- logout
 */
const UserMenu = ({showContent, setShowContent}) => {
    const selectorClasses = ["selector__content"];
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();

    if (showContent) {
        selectorClasses.push("active")
    }

    //выходим из профиля и подчищаем данные за собой
    function logout() {
        clearUserData(dispatch);
        setShowContent(false);
    }

    return (
        <div className={selectorClasses.join(" ")}
             onMouseLeave={() => setShowContent(false)}>
            <ul className="options">
                <li onClick={() => navigate("/profile")}>{t("page.profile.title")}</li>
                <li onClick={() => logout()}>{t("button.logout")}</li>
            </ul>
        </div>
    );
};

export default UserMenu;