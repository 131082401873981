import React from 'react';
import InformationBlocks from "../../../components/common/ui/text/informationblock/InformationBlocks";
import PersonalInfoBlock from "../view/components/PersonalInfoBlock";

/**
 * Вкладка "Основное" в профиле пользователя
 */
const UserBaseInfoTab = ({user}) => {
    return (
        <InformationBlocks>
            <PersonalInfoBlock user={user}/>
        </InformationBlocks>
    );
};

export default UserBaseInfoTab;