import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import TableCell from "./TableCell";
import {showModal} from "../../../../../app/store/modalReducer";
import DeleteRecordPopupBody
    from "../../../../../pages/adminpanel/dictionaries/geo/countries/table/DeleteRecordPopupBody";
import {useDispatch} from "react-redux";

/**
 * Ячейка в таблице "Удалить элемент"
 */
const DeleteItemTableCell = ({id, title, metadata, cellSize = 10}) => {
    const dispatch = useDispatch();

    function showPopup(id, title) {
        dispatch(showModal({
            popup: {
                title: "",
                body: <DeleteRecordPopupBody id={id} metadata={metadata} title={title}/>
            }
        }))
    }

    return (
        <TableCell cellSize={cellSize} onClick={() => showPopup(id, title)}>
            <div className="simple-link">
                <FontAwesomeIcon icon={faTrash} size={"xl"}/>
            </div>
        </TableCell>
    );
};

export default DeleteItemTableCell;