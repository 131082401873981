import React from 'react';
import {useTranslation} from "react-i18next";
import TableNoResults from "../../../../../../components/common/table/TableNoResults";
import SimpleTable from "../../../../../../components/common/table/simple/SimpleTable";
import CountriesTableTitle from "./CountriesTableTitle";
import CountryLines from "./CountryLines";

/**
 * Таблица со странами
 */
const CountriesTable = ({countries, error}) => {
    const {t} = useTranslation();
    if (error !== "") {
        return (<TableNoResults title={error}/>);
    }
    if (!countries.length) {
        return (<TableNoResults title={t("adminPanel.dictionaries.isEmpty")}/>);
    }

    return (
        <SimpleTable>
            <CountriesTableTitle title={
                {
                    name: t('form.common.fields.title'),
                    alpha2: t('adminPanel.dictionaries.countries.alpha2'),
                    alpha3: t('adminPanel.dictionaries.countries.alpha3'),
                    isoCode: t('adminPanel.dictionaries.countries.isoCode'),
                    continent: t('adminPanel.dictionaries.countries.continent'),
                    actions: t('form.common.fields.actions')
                }
            }/>
            <CountryLines countries={countries}/>
        </SimpleTable>
    );
};

export default CountriesTable;