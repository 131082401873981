import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {getActivePetForUpdatePetInfo} from "../../../newpet/helpers/petHelper";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import {TATTOO_NUMBER} from "../../../newpet/helpers/petFormConstants";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import PetTattooNumberItem from "../../../common/components/form/PetTattooNumberItem";
import {updatePetIdentificationInfo} from "../../../../../app/store/userReducer";
import PetService from "../../../newpet/api/PetService";
import {useTranslation} from "react-i18next";

/**
 * Работа с номером татуировки питомца
 */
const PetTattooNumber = () => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [tattooNumberErrorMessage, setTattooNumberErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const about = `Клеймо - обыкновенная татуировка, которая содержит буквенно-цифровой шифр. 
Это своего рода регистрационный документ, который ставят либо на ушах животного, либо в паху.\n\n
Клеймо обычно наносят собакам в питомниках и клубах. Затем этот код заносят в специальную метрику.
По ней в дальнейшем можно узнать информацию о конкретной собаке:
дату рождения, в каком питомнике родилась, окрас и т. д.\n\n
Коты не подвергаются процедуре клеймения: породистый котенок имеет паспорт с обозначением его имени,
даты рождения, породы. Однако, некоторые хозяева самостоятельно клеймируют питомца.`;

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage("");
        setTattooNumberErrorMessage("");
    }

    function isButtonActive() {
        return activePet.identificationInfo?.tattooNumber !== watch(TATTOO_NUMBER) && watch(TATTOO_NUMBER) !== '';
    }

    function init() {
        setValue(TATTOO_NUMBER, activePet.identificationInfo?.tattooNumber);
    }

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetTattooNumber(activePet.id, data.tattooNumber, token);
        response.then((resp) => {
            dispatch(updatePetIdentificationInfo({
                identificationInfo: resp.data.data
            }))
        }, (error) => {
            setTattooNumberErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.pet.tattoo')} backTo={`/pets/${params.id}`}
                                     content={about}/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <PetTattooNumberItem errors={errors} register={register}
                                     hasValue={getValues(TATTOO_NUMBER) !== ''} tabIndex='1'/>
                <FormError title={tattooNumberErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetTattooNumber;