import React from 'react';

/**
 * Заголовок информационного блока
 */
const InformationBlockHeader = ({title}) => {
    return (
        <div className='information-block-content__header'>
            <span>{title}</span>
        </div>
    );
};

export default InformationBlockHeader;