import React from 'react';
import Content from "../../components/common/Content";
import {useTranslation} from "react-i18next";

/**
 * Контент главной страницы десктопной версии для зарегистрированного пользователя
 */
const DesktopIndexContentAuthUser = () => {
    const {t} = useTranslation();    return (
        <Content>
            {t("common.comingSoon")}
        </Content>
    );
};

export default DesktopIndexContentAuthUser;