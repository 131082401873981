import React from 'react';

/**
 * Заголовок по центру
 */
const SmallTitleByCenter = ({children}) => {
    return (
        <div className="title-by-center">
            <h3>{children}</h3>
        </div>
    );
};

export default SmallTitleByCenter;