import React, {useEffect, useState} from 'react';
import Content from "../../../components/common/Content";
import {useNavigate, useParams} from "react-router-dom";
import UserService from "../../../service/UserService";
import PageTitle from "../../../components/common/PageTitle";
import {useTranslation} from "react-i18next";
import Spinner from "../../../components/common/Spinner";
import GoToMainPageWithReasonIfError from "./GoToMainPageWithReasonIfError";
import {useSelector} from "react-redux";
import DoReset from "./DoReset";

/**
 * Страница для восстановления забытого пароля
 */
const ResetPassword = () => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    const navigate = useNavigate();
    const params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        checkResetPassPossibility();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function checkResetPassPossibility() {
        if (isUserAuth) {
            navigate("/");
            return;
        }

        setIsLoading(true)
        const response = UserService.resetPasswordCheck(params.code);
        response.then(() => {
            setError(false);
            setIsLoading(false)
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    return (
        <Content>
            <PageTitle title={t("page.resetPassword.title")}/>
            {isLoading ? <Spinner/> :
                error ? <GoToMainPageWithReasonIfError errorMessage={errorMessage}/> :
                    <DoReset resetCode={params.code}/>}
        </Content>
    );
};

export default ResetPassword;