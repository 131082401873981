import React from 'react';
import LoginFormTitle from "../loginform/LoginFormTitle";
import LoginForm from "../loginform/LoginForm";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {showModal} from "../../../../../app/store/modalReducer";

/**
 * Заголовок для формы регистрации.
 * Используется для модального окна
 */
const UserRegisterFormTitle = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function showRegistrationForm() {
        dispatch(showModal({
            popup: {
                title: <LoginFormTitle/>,
                body: <LoginForm/>
            }
        }))
    }

    return (
        <div>
            <span className="link"
                  onClick={() => showRegistrationForm()}>
                {t("form.login.title")}
            </span>
            <span className="link active-link">{t("form.registration.title")}</span>
        </div>
    );
};

export default UserRegisterFormTitle;