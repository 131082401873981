import React from 'react';

/**
 * Компонент, который отвечает за отрисовку заголовка по центру
 */
const TitleByCenter = ({children}) => {
    return (
        <div className="title-by-center">
            <h2>{children}</h2>
        </div>
    );
};

export default TitleByCenter;