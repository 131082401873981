import React, {useEffect, useRef, useState} from 'react';
import {
    addPetSteps,
    COMPLEX_VACCINATION_DATE,
    DEWORMING_DATE,
    FLEA_AND_TICK_TREATMENT_DATE,
    RABIES_VACCINATION_DATE
} from "../../../helpers/petFormConstants";
import PetForm from "../PetForm";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {
    checkIfInvalidStepsExists,
    clearSessionDataForPet,
    formDto,
    petFormStepFiveSchema,
    removeCurrentStepFromValidated,
    setCurrentStepValidated,
    stepBack
} from "../../../helpers/petHelper";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import ButtonGroup from "../../../../../../components/common/ui/buttons/ButtonGroup";
import OrangeButtonFilled from "../../../../../../components/common/ui/buttons/OrangeButtonFilled";
import LightBrownButton from "../../../../../../components/common/ui/buttons/LightBrownButton";
import {getStoredItem, storeItem} from "../../../../../../components/common/form/helper/formHelper";
import FormError from "../../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {STRING} from "../../../../../../components/common/form/helper/formConstants";
import Spinner from "../../../../../../components/common/Spinner";
import PetService from "../../../api/PetService";
import {useDispatch, useSelector} from "react-redux";
import {setActivePet, setUserPets} from "../../../../../../app/store/userReducer";
import CalendarInputFormItem from "../../../../../../components/common/form/parts/inputs/wrappers/CalendarInputFormItem";


/**
 * Пятый (заключительный) шаг добавления нового питомца
 */
const PetFormStepFive = () => {
    const {t} = useTranslation();
    const token = useSelector(state => state.userReducer.token);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const CURRENT_STEP = 5;
    const formValid = useRef(false);
    const [invalidStepsErrorMessage, setInvalidStepsErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setIsLoading] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue
    } = useForm({
        resolver: yupResolver(petFormStepFiveSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    /**
     * Дополнительные проверки
     */
    function additionalValidation() {
        const hasInvalidSteps = checkIfInvalidStepsExists(setInvalidStepsErrorMessage);
        if (hasInvalidSteps) {
            formValid.current = false;
        }
    }

    /**
     * Зачищаем тексты ошибок полей
     */
    function clearErrorMessages() {
        formValid.current = true;
        setInvalidStepsErrorMessage("");
    }

    /**
     * Обработка события на форме
     */
    function submitForm() {
        clearErrorMessages();
        setCurrentStepValidated(CURRENT_STEP);
        additionalValidation();
        if (formValid.current === false) {
            return;
        }

        setDisabledSubmit(true);
        setIsLoading(true)

        const dto = formDto();
        const resp = PetService.save(dto, token)
        resp.then(
            (resp) => {
                clearSessionDataForPet();
                setError(false);
                setIsLoading(false);
                //просечиваем обновленный список питомцев и актуального питомца в стор
                dispatch(setUserPets(
                    {
                        pets: resp.data.data.pets
                    }));
                dispatch(setActivePet({
                    activePet: resp.data.data.activePet
                }))
                navigate(`/pets/${resp.data.data.activePet.id}`);
            },
            (error) => {
                setIsLoading(false)
                setError(true)
                setDisabledSubmit(false);
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            })
    }

    function init() {
        setValue(DEWORMING_DATE, getStoredItem(DEWORMING_DATE, STRING));
        setValue(FLEA_AND_TICK_TREATMENT_DATE, getStoredItem(FLEA_AND_TICK_TREATMENT_DATE, STRING));
        setValue(RABIES_VACCINATION_DATE, getStoredItem(RABIES_VACCINATION_DATE, STRING));
        setValue(COMPLEX_VACCINATION_DATE, getStoredItem(COMPLEX_VACCINATION_DATE, STRING));
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        removeCurrentStepFromValidated(CURRENT_STEP);
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // hasValue={true} чтобы лейбл не заслонял плейсхолдер
    return (
        <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
            <CalendarInputFormItem fieldName={DEWORMING_DATE} register={register}
                                   placeholder={t('page.pet.newPet.dewormingDate')} tabIndex='1'
                                   onBlurFunction={storeItem} errors={errors} hasValue={true}/>

            <CalendarInputFormItem fieldName={FLEA_AND_TICK_TREATMENT_DATE} register={register}
                                   placeholder={t('page.pet.newPet.fleaAndTickTreatmentDate')} tabIndex='2'
                                   onBlurFunction={storeItem} errors={errors} hasValue={true}/>

            <CalendarInputFormItem fieldName={RABIES_VACCINATION_DATE} register={register}
                                   placeholder={t('page.pet.newPet.rabiesVaccinationDate')} tabIndex='3'
                                   onBlurFunction={storeItem} errors={errors} hasValue={true}/>

            <CalendarInputFormItem fieldName={COMPLEX_VACCINATION_DATE} register={register}
                                   placeholder={t('page.pet.newPet.complexVaccinationDate')} tabIndex='3'
                                   onBlurFunction={storeItem} errors={errors} hasValue={true}/>

            {invalidStepsErrorMessage !== "" ? <FormError title={invalidStepsErrorMessage}/> : null}
            {error ? <FormError title={errorMessage}/> : null}
            {loading ?
                <Spinner/> :
                <ButtonGroup>
                    <LightBrownButton onClick={() => stepBack(CURRENT_STEP, addPetSteps, navigate)}>
                        Назад
                    </LightBrownButton>
                    <OrangeButtonFilled disabled={disabledSubmit} onClick={handleSubmit(submitForm)}>
                        Сохранить
                    </OrangeButtonFilled>
                </ButtonGroup>
            }
        </PetForm>
    );
};

export default PetFormStepFive;