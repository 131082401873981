import React from 'react';
import {hasValue} from "../../../../../app/helper/commonHelper";
import TopMenuPetIcon from "./TopMenuPetIcon";
import {useSelector} from "react-redux";
import TopMenuOtherPetsIcon from "./TopMenuOtherPetsIcon";

/**
 * Блок, отнсящийся к питомцам в верхнем меню
 */
const TopMenuPetBlock = () => {
    const userPets = useSelector(state => state.userReducer.pets);
    const activePet = useSelector(state => state.userReducer.activePet);

    // показать лого питомца или иконку "добавить"
    function showActivePetOrDefault() {
        let activePetInfo = null
        if (hasValue(userPets) && hasValue(activePet)) {
            //проверим, есть ли в списке питомцев такой, у кого id совпадает с адресной строкой
            activePetInfo = userPets.filter(pet => activePet.id === pet.id)[0]
        }
        return activePetInfo;
    }

    function showNewPetIcon() {
        const activePet = showActivePetOrDefault();
        if (activePet === null || activePet === undefined) {
            return null;
        }
        return <TopMenuPetIcon/>
    }

    function showOtherPetsIcon() {
        if (userPets?.length === null || userPets?.length < 2) {
            return null;
        }
        return <TopMenuOtherPetsIcon/>
    }

    return (
        <div className='top-menu__pets-block'>
            <TopMenuPetIcon petInfo={showActivePetOrDefault()}/>
            <div className='top-menu__pets-additional_elements'>
                {showOtherPetsIcon()}
                {showNewPetIcon()}
            </div>
        </div>
    );
};

export default TopMenuPetBlock;