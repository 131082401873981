import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {availableAnimalKinds, CAT, DOG} from "../../../app/const/appConst";
import CustomSelectorTwoVars from "../../../components/common/ui/select/CustomSelectorTwoVars";

/**
 * Фильтр по типу животных: собака/кошка
 */
const AnimalKindSelector = ({animalKind, setAnimalKind, catTitle = null, dogTitle = null}) => {
    const {t} = useTranslation();
    const CAT = {value: "CAT", title: t('page.breeds.cats')}
    const DOG = {value: "DOG", title: t('page.breeds.dogs')}


    function fillAnimalKindObj(animalKind) {
        switch (animalKind) {
            case CAT :
                setAnimalKind(prevState => ([...prevState, {
                    type: CAT.value,
                    title: catTitle ? catTitle : CAT.title
                }]))
                return;
            case DOG :
                setAnimalKind(prevState => ([...prevState, {
                    type: DOG.value,
                    title: dogTitle ? dogTitle : DOG.title
                }]))
                return;
            default:
                return;
        }
    }

    useEffect(() => {
        availableAnimalKinds.map(animalKind => fillAnimalKindObj(animalKind))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <CustomSelectorTwoVars value={animalKind} setValue={setAnimalKind} first={CAT} second={DOG}/>
    );
};

export default AnimalKindSelector;