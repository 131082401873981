import React from 'react';

/**
 * Содержимое раздела админ-панели
 */
const PanelPageContent = ({title = null, children}) => {
    return (
        <div className='admin-panel__content'>
            {title === null ? null :
                <div className='admin-panel__content_header'>
                    <h1>{title}</h1>
                </div>
            }
            <div className='admin-panel__content_info'>
                {children}
            </div>
        </div>
    );
};

export default PanelPageContent;