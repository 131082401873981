import React from 'react';

/**
 * Заголовок для таблицы с одной колонкой без колонки "Действия"
 */
const TableTitleOneColNoActions = ({title}) => {
    return (
        <div>
            <div className="table-line table-title">
                <div className="table-line__cell cell-100">
                    <span className="table-line__description">{title.first}</span>
                </div>
            </div>
        </div>
    );
};

export default TableTitleOneColNoActions;