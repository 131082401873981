import React from 'react';
import {BREED_FACTS, FACT} from "../../helpers/breedFormConstants";
import FormItem from "../../../../components/common/form/parts/inputs/wrappers/FormItem";
import HiddenInput from "../../../../components/common/form/parts/inputs/HiddenInput";
import {ACTION_ADD, CUSTOM_INPUT_CLASS} from "../../../../components/common/form/helper/formConstants";
import {ERROR_CLASS} from "../../../../utils/stringutils";
import {storeArrayElement} from "../../../../components/common/form/helper/formHelper";
import RemoveButton from "../../../../components/common/ui/buttons/RemoveButton";
import {addFact, removeFact} from "../../helpers/breedHelper";
import SmallTitleByCenter from "../../../../components/common/ui/text/SmallTitleByCenter";
import {useTranslation} from "react-i18next";
import DarkGreenButtonByCenter from "../../../../components/common/ui/buttons/DarkGreenButtonByCenter";

/**
 * Динамически формируемый элемент формы "Факт о породе питомца"
 */
const BreedFactsFormElement = ({
                                   factsIds,
                                   breedFacts,
                                   setBreedFacts,
                                   errors,
                                   action,
                                   register,
                                   getValues,
                                   setValue
                               }) => {
    const {t} = useTranslation();

    return (
        <>
            <SmallTitleByCenter>{t("form.newBreed.facts")}</SmallTitleByCenter>
            {breedFacts.map((el, index) => {
                const fieldName = `${BREED_FACTS}[${index}]`
                return (
                    <FormItem key={el.id} classes='form-item group'>
                        <HiddenInput fieldName={`${fieldName}.id`} value={el.id} register={register}/>
                        <input type="text"
                               className={errors[BREED_FACTS]?.[index] ? `${CUSTOM_INPUT_CLASS} ${ERROR_CLASS}` : `${CUSTOM_INPUT_CLASS}`}
                               name={`${fieldName}.${FACT}`}
                               placeholder={t("form.newBreed.fact")}
                               {...register(`${fieldName}.${FACT}`)}
                               onBlur={(e) =>
                                   action === ACTION_ADD ? storeArrayElement(el.id, BREED_FACTS, FACT, e.target.value)
                                       : null}/>
                        <RemoveButton
                            onClick={() => removeFact(index, getValues, setValue, setBreedFacts, action === ACTION_ADD)}/>
                    </FormItem>
                )
            })}
            <DarkGreenButtonByCenter onClick={() => addFact(factsIds, breedFacts, setBreedFacts)}>
                {t("button.add")}
            </DarkGreenButtonByCenter>
        </>
    );
};

export default BreedFactsFormElement;