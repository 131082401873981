import React from 'react';
import CountryLine from "./CountryLine";

/**
 * Строки в таблице со странами
 */
const CountryLines = ({countries}) => {
    return (
        <>
            {countries.map(country =>
                <CountryLine key={country.id} country={country}/>
            )}
        </>
    );
};

export default CountryLines;