import React from 'react';
import TextInput from "../TextInput";
import {ERROR_CLASS, INPUT_CLASS} from "../../../../../../utils/stringutils";
import FormError from "./FormError";
import FormItem from "./FormItem";

/**
 * Компонент формы "Обычный текстовый инпут"
 * надстройка над компонентом TextInput
 *
 * Помимо самого инпута снизу выводит текст ошибки если она есть.
 */
const TextInputFormItem = ({
                               errors,
                               fieldName,
                               placeholder,
                               register,
                               onBlurFunction = null,
                               dispatchFunction = null,
                               hasValue = false,
                               tabIndex
                           }) => {
    return (
        <FormItem>
            <TextInput
                classes={errors[fieldName] ? `${INPUT_CLASS} ${ERROR_CLASS}` : `${INPUT_CLASS}`}
                register={register} fieldName={fieldName} placeholder={placeholder}
                tabIndex={tabIndex} onBlurFunction={onBlurFunction} hasValue={hasValue}
                dispatchFunction={dispatchFunction}/>
            {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default TextInputFormItem;