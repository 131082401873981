import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import UserRegisterForm from "../../../components/common/form/completeforms/registrationform/UserRegisterForm";
import {useSelector} from "react-redux";
import Content from "../../../components/common/Content";
import {useTranslation} from "react-i18next";

/**
 * Компонент-страница для входа.
 * На неё редиректится пользователь, если пытается зайти в раздел для зарегистрированных пользователей
 */
const RegistrationPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);

    function checkIsUserAuth() {
        if (isUserAuth) {
            navigate("/")
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        checkIsUserAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Content>
            <div className="form-with-title">
                <div className="form-title">
                    <span className="link" onClick={() => navigate("/login")}>{t("form.login.title")}</span>
                    <span className="link active-link">{t("form.registration.title")}</span>
                </div>
                <UserRegisterForm/>
            </div>
        </Content>
    );
};

export default RegistrationPage;