import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {showModal} from "../../../../app/store/modalReducer";
import DeleteBreedPopupBody from "../DeleteBreedPopupBody";
import MobileBreedLine from "./mobile/MobileBreedLine";
import DesktopBreedLine from "./desktop/DesktopBreedLine";

/**
 *  Строка в таблицу с информацией о породе
 */
const BreedLine = ({breedInfo, withActions = false}) => {
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    const dispatch = useDispatch();

    function showPopup(id, title) {
        dispatch(showModal({
            popup: {
                title: "",
                body: <DeleteBreedPopupBody breedId={id} title={title}/>
            }
        }))
    }

    if (isMobile) {
        return <MobileBreedLine breedInfo={breedInfo} withActions={withActions} deleteFunction={showPopup}/>
    }
    return <DesktopBreedLine breedInfo={breedInfo} withActions={withActions} deleteFunction={showPopup}/>
};

export default BreedLine;