import React from 'react';

/**
 * Переключаемые вкладки (2 шт.)
 */
const SwitchableTwoTabs = ({
                               activeTab, setActiveTab,
                               firstTabAlias, firstTabName,
                               secondTabAlias, secondTabName,
                               disabledFirst = false, disabledSecond = false
                           }) => {

    function switchTab(switchToTab) {
        if (activeTab !== switchToTab) {
            setActiveTab(switchToTab)
        }
    }

    return (
        <div className="tabs-wrapper">
            <div className={disabledFirst ? "tabs-item disabled" : activeTab === firstTabAlias ? "tabs-item active" : "tabs-item"}
                 onClick={() => disabledFirst ? null : switchTab(firstTabAlias)}>
                <span>{firstTabName}</span>
            </div>
            <div className={disabledSecond ? "tabs-item disabled" : activeTab === secondTabAlias ? "tabs-item active" : "tabs-item"}
                 onClick={() => disabledSecond? null : switchTab(secondTabAlias)}>
                <span>{secondTabName}</span>
            </div>
        </div>
    );
};

export default SwitchableTwoTabs;