import React from 'react';
import TableTitleTwoColsNoActions from "./TableTitleTwoColsNoActions";
import TableTitleTwoColsWithActions from "./TableTitleTwoColsWithActions";

/**
 * Динамический заголовок для таблицы с двумя колонками
 * col_1: 75%
 * col_2: 25%
 */
const TableTitleTwoCols = ({title}) => {
    if (title.actions === undefined || title.actions === null || title.actions === '') {
        return <TableTitleTwoColsNoActions title={title}/>
    }
    return <TableTitleTwoColsWithActions title={title}/>
};

export default TableTitleTwoCols;