import React from 'react';
import {useSelector} from "react-redux";
import MobileIndexContentAuthUser from "./MobileIndexContentAuthUser";
import IndexContentUnregisterUser from "./IndexContentUnregisterUser";

/**
 * Главная страница мобильной версии
 */
const MobileIndexPage = () => {
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);
    return (
        <>
            {isUserAuth ?
                <MobileIndexContentAuthUser/> :
                <IndexContentUnregisterUser/>}
        </>
    );
};

export default MobileIndexPage;