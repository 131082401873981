import React, {useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";
import FormError from "../../../../components/common/form/parts/inputs/wrappers/FormError";
import Spinner from "../../../../components/common/Spinner";
import UserService from "../../../../service/UserService";
import SubmitButton from "../../../../components/common/ui/buttons/SubmitButton";
import {PASSWORD} from "../../../../components/common/form/helper/commonFormConstants";
import PasswordFormItem from "../../../../components/common/form/parts/inputs/wrappers/PasswordFormItem";
import Form from "../../../../components/common/form/abstract/Form";

/**
 * Форма для сброса пароля
 */
const ResetPasswordForm = ({resetCode, setSuccessReset, setSuccessMsg}) => {
    const {t} = useTranslation();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setIsLoading] = useState(false);
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    const schema = yup.object().shape({
        password: yup.string().min(8, t("form.common.errors.minEightSymbols"))
            .required(t("form.common.errors.fieldRequired")),
        repeatPassword: yup.string()
            .oneOf([yup.ref("password"), null], t("form.common.errors.passMustBeTheSame"))
    });

    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function submitForm(data) {
        if (disabledSubmit) {
            return;
        }
        setError(false)
        setDisabledSubmit(true);
        setIsLoading(true);
        const response = UserService.resetPassword(data.password, resetCode);
        response.then((resp) => {
            setError(false);
            setDisabledSubmit(false);
            setIsLoading(false);
            setSuccessReset(true);
            setSuccessMsg(resp.data.data);
        }, (error) => {
            setError(true);
            setDisabledSubmit(false);
            setIsLoading(false);
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
    }

    return (
        <Form handleSubmit={handleSubmit} submitForm={submitForm}>
            {/*Пароль*/}
            <PasswordFormItem fieldName={PASSWORD} register={register}
                              placeholder={t("form.resetPassword.password")} tabIndex='1'
                              autocomplete="new-password" errors={errors}/>

            {/*Повторить пароль*/}
            <PasswordFormItem fieldName="repeatPassword" register={register}
                              placeholder={t("form.resetPassword.repeatPassword")} tabIndex='2'
                              autocomplete="new-password" errors={errors}/>

            {error ? <FormError title={errorMessage}/> : null}
            {loading ? <Spinner/> : <SubmitButton label={t("button.save")}/>}
        </Form>
    );
};

export default ResetPasswordForm;