import React from 'react';
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {hasValue} from "../../../../../app/helper/commonHelper";

/**
 * Блок с расширенной информацией о питомце
 */
const OtherInfoBlock = ({pet}) => {
    const infoMap = [
        {
            title: 'Характер',
            value: pet.character,
            hasValue: hasValue(pet.character)
        }
    ];

    return (
        <InformationBlock title='Расширенная информация' infoMap={infoMap}/>
    );
};

export default OtherInfoBlock;