import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistReducer, persistStore,} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import mobileReducer from "./isMobileReducer";
import modalReducer from "./modalReducer";
import burgerReducer from "./burgerReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    mobileReducer: mobileReducer,
    burgerReducer: burgerReducer,
    modalReducer: modalReducer,
    userReducer: userReducer,
})

const persistConfig = {
    key: 'root',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export const persistor = persistStore(store); //обертка над стором
export default store;

