import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
    popup: {title: '', body: ''}
};

const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: initialState,
    reducers: {
        showModal(state, action) {
            state.isActive = true;
            state.popup.title = action.payload.popup.title;
            state.popup.body = action.payload.popup.body;
        },
        hideModal(state) {
            state.isActive = false;
            state.popup.title = '';
            state.popup.body = '';
        }
    }
})

export default modalSlice.reducer;
export const {showModal, hideModal} = modalSlice.actions;
