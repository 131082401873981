import React from 'react';
import BreedLines from "../BreedLines";
import SimpleTable from "../../../../../components/common/table/simple/SimpleTable";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import TableTitleOneCol from "../../../../../components/common/table/simple/title/onecol/TableTitleOneCol";

/**
 * Таблица с породами для мобильной версии
 * Одна колонка - название породы
 */
const MobileBreedsTable = ({breedsInfo}) => {
    const {t} = useTranslation();
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);
    return (
        <SimpleTable>
            <TableTitleOneCol title={
                {
                    first: t("page.breeds.table.title"),
                    actions: isAdmin || isModerator ? "" : ""
                }
            }/>
            <BreedLines breedsInfo={breedsInfo} needActions={isAdmin || isModerator}/>
        </SimpleTable>
    );
};

export default MobileBreedsTable;