import React from 'react';
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {useTranslation} from "react-i18next";
import {LIVING_CONDITIONS, LIVING_ENVIRONMENT} from "../../petConst";
import {hasValue} from "../../../../../app/helper/commonHelper";
import {useParams} from "react-router-dom";

/**
 * Блок с информацией по условию содержания питомца
 */
const LivingConditionsBlock = ({pet}) => {
    const {t} = useTranslation();
    const params = useParams();
    const infoMap = [
        {
            title: 'Место проживания',
            value: t(LIVING_ENVIRONMENT[pet.livingEnvironment]),
            hasValue: hasValue(LIVING_ENVIRONMENT[pet.livingEnvironment]),
            onClickPath: `/pets/${params.id}/living-environment`
        },
        {
            title: 'Условия проживания',
            value: t(LIVING_CONDITIONS[pet.livingConditions]),
            hasValue: hasValue(LIVING_CONDITIONS[pet.livingConditions]),
            onClickPath: `/pets/${params.id}/living-conditions`
        }
    ];

    return (
        <InformationBlock title='Условия содержания' infoMap={infoMap}/>
    );
};

export default LivingConditionsBlock;