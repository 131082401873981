import React from 'react';
import {useSelector} from "react-redux";

/**
 * Обертка над контентом страницы по добавлению питомца
 */
const PetContentWrapper = ({children}) => {
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    return (
        <div className={isMobile ? "pet-content__wrapper mobile" : "pet-content__wrapper"}>
            {children}
        </div>
    );
};

export default PetContentWrapper;