import React from 'react';
import CustomSelectorTwoVarsInTable from "../../../../../components/common/ui/select/CustomSelectorTwoVarsInTable";
import {FEMALE_GENDER, MALE_GENDER} from "../../../viewpet/petConst";
import {PET_GENDER} from "../../../newpet/helpers/petFormConstants";

/**
 * Компонент формы "Гендер питомца"
 */
const PetGenderFormItem = ({value, setValue, register, hookFormSetValue, toStore = false, errors}) => {
    return (
        <CustomSelectorTwoVarsInTable value={value} setValue={setValue} first={MALE_GENDER}
                                      second={FEMALE_GENDER} fieldName={PET_GENDER} toStore={toStore}
                                      register={register} hookFormSetValue={hookFormSetValue} errors={errors}/>
    );
};

export default PetGenderFormItem;