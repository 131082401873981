import React from 'react';

/**
 * Динамический заголовок для таблицы с одной информационной колонкой + колонкой с действиями
 * col_1: 90%
 * col_3: 10%
 */
const TableTitleOneColWithActions = ({title}) => {
    return (
        <div className="table-line table-title">
            <div className="table-line__cell cell-90">
                <span className="table-line__description">{title.first}</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">{title.actions}</span>
            </div>
        </div>
    );
};

export default TableTitleOneColWithActions;