import React from 'react';
import PanelContent from "../../../components/adminpanel/PanelContent";
import {Dictionarylink} from "./dictionarylink";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

/**
 * Работа со справочниками
 */
const Dictionaries = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <PanelContent title={t('adminPanel.dictionaries.title')}>
            <ul className='dictionary-list'>
                {Dictionarylink.map(dictionary => {
                    return (
                        <li className='dictionary-item' key={dictionary.title}
                            onClick={() => navigate(`${dictionary.path}`)}>{t(dictionary.title)}</li>
                    );
                })}
            </ul>
        </PanelContent>
    );
};

export default Dictionaries;