import React from 'react';
import {getPetShortName} from "../../../../../pages/pets/utils/petUtils";
import {useNavigate} from "react-router-dom";
import PetIcon from "./PetIcon";
import TopMenuNewPet from "./TopMenuNewPet";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";

/**
 * Лого питомца в верхнем меню
 * petInfo - сведения об активном (текущем) питомце. Если null, то выводим кнопку "добавить"
 */
const TopMenuPetIcon = ({petInfo = null}) => {
    const navigate = useNavigate();

    function getPetLogo(petInfo) {
        return (
            <PetIcon>
                <div className="pet-logo"
                     onClick={() => navigate(`/pets/${petInfo.id}`)}>
                    {isEmptyOrNull(petInfo.logo) ? <span>{getPetShortName(petInfo.name)}</span>
                        : <img src={`data:image/jpeg;base64,${petInfo.logo.fileBase64}`} alt={petInfo.name}/>}
                </div>
            </PetIcon>
        )
    }

    if (petInfo === null || petInfo === undefined) {
        return (
            <TopMenuNewPet/>
        );
    } else {
        return getPetLogo(petInfo);
    }
};

export default TopMenuPetIcon;