import React, {useEffect, useState} from 'react';
import Content from "../../components/common/Content";
import {useSelector} from "react-redux";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import SwitchableTwoTabs from "../../components/common/ui/tabs/SwitchableTwoTabs";
import {useTranslation} from "react-i18next";
import {USER_PROFILE_BASIC_INFO_TAB, USER_PROFILE_SETTINGS_TAB} from "./profileConst";
import UserBaseInfoTab from "./tabs/UserBaseInfoTab";
import UserSettingsTab from "./tabs/UserSettingsTab";
import CircleTemplateForImage from "../../components/common/photo/CircleTemplateForImage";

/**
 * Профиль пользователя
 */
const Profile = () => {
    const {t} = useTranslation();
    const userPhoto = useSelector(state => state.userReducer.photo);
    const user = useSelector(state => state.userReducer);
    const [activeTab, setActiveTab] = useState(USER_PROFILE_BASIC_INFO_TAB);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    return (
        <Content>
            <Breadcrumbs elements={[{page: t("page.profile.title"), path: ''}]}/>
            <CircleTemplateForImage image={userPhoto} navigatePath='/profile/photo'/>
            <SwitchableTwoTabs activeTab={activeTab} setActiveTab={setActiveTab}
                               firstTabAlias={USER_PROFILE_BASIC_INFO_TAB} firstTabName='Основное'
                               secondTabAlias={USER_PROFILE_SETTINGS_TAB} secondTabName='настройки'
                               disabledSecond={true}/>
            {activeTab === USER_PROFILE_BASIC_INFO_TAB ? <UserBaseInfoTab user={user}/> : <UserSettingsTab/>}
        </Content>
    );
};

export default Profile;