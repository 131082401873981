import React from 'react';
import SortingSelect from "../../../../../components/common/ui/select/SortingSelect";
import {PET_BREED} from "../../../newpet/helpers/petFormConstants";
import FormItem from "../../../../../components/common/form/parts/inputs/wrappers/FormItem";

/**
 * Компонент формы "Порода питомца"
 */
const PetBreedFormItem = ({
                              value, onChangeFunction = null, onBlurFunction = null,
                              options, errorMessage = '', disabled = false
                          }) => {
    return (
        <FormItem>
            <SortingSelect
                value={value}
                onChangeFunction={onChangeFunction}
                defaultValue={errorMessage === '' ? "порода" : errorMessage}
                options={options}
                fieldName={PET_BREED}
                onBlurFunction={onBlurFunction}
                disabled={disabled}/>
        </FormItem>
    );
};

export default PetBreedFormItem;