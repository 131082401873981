import React from 'react';
import {getIconByAlias} from "../../../../../app/helper/commonHelper";
import {Link, useLocation} from "react-router-dom";
import {TopMenuLinks} from "../../../menu/menulinks";

/**
 * Пункты меню, которые находятся в хэдере
 */
const TopMenuItems = () => {
    const location = useLocation();
    function currentPath(path) {
        return location.pathname === path
    }

    return (
        <ul className="top-menu__links">
            {TopMenuLinks.map(page => {
                return (
                    <li key={page.title}
                        className={currentPath(page.path) ? "top-menu__item active" : "top-menu__item"}>
                        {currentPath(page.path) ?
                            <span>{getIconByAlias(page.alias)}</span> :
                            <Link to={page.path}>
                                {getIconByAlias(page.alias)}
                            </Link>
                        }
                    </li>
                );
            })}
        </ul>
    );
};

export default TopMenuItems;