import React from 'react';

/**
 * Компонент параграфа, состоящего из заголовка и текста
 */
const ArticleWithHeader = ({title, text, needMargin = false}) => {
    return (
        <div className={needMargin ? "article-with-title margin-top" : "article-with-title"}>
            <div className="article-with-title__title">
                <span>{title}</span>
            </div>
            <div className="article-with-title__text">
                <p>{text}</p>
            </div>
        </div>
    );
};

export default ArticleWithHeader;