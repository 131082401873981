import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {hideModal, showModal} from "../../../../../../app/store/modalReducer";
import AutoCloseableModalWithTitle from "../../../../../../components/common/ui/modal/AutoCloseableModalWithTitle";
import FormError from "../../../../../../components/common/form/parts/inputs/wrappers/FormError";
import Spinner from "../../../../../../components/common/Spinner";
import ButtonGroup from "../../../../../../components/common/ui/buttons/ButtonGroup";
import OrangeButtonBlank from "../../../../../../components/common/ui/buttons/OrangeButtonBlank";
import LightBrownButtonBlank from "../../../../../../components/common/ui/buttons/LightBrownButtonBlank";
import {removeRecord} from "../../../../../../service/dictionaryService";

/**
 * Тело всплывающего окна при нажатии на удаление записи
 */
const DeleteRecordPopupBody = ({id, metadata, title}) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.userReducer.token);
    const [message, setMessage] = useState();
    const [error, setError] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    function showSuccessMessage(text) {
        dispatch(showModal({
            popup: {
                title: "",
                body: <AutoCloseableModalWithTitle text={text} delay={1000}/>
            }
        }));
    }

    function deleteRecord(id, backControllerName) {
        setError(false)
        setIsLoading(true);
        const resp = removeRecord(id, backControllerName, token);
        resp.then((resp) => {
            setError(false)
            setIsLoading(false);
            showSuccessMessage(resp.data.data)
        }, (err) => {
            setError(true)
            setMessage(err.response?.data?.messages ?
                err.response?.data?.messages?.ERROR[0] : err.message)
        })
        setIsLoading(false)
    }

    return (
        <div>
            <span>{t('form.common.phrases.wantDelete')} {t(metadata.titlePrepositionalCase)} {title} ?</span>
            {error ? <FormError title={message}/> : null}
            {loading ? <Spinner/>
                : <ButtonGroup>
                    <OrangeButtonBlank onClick={() => dispatch(hideModal())}>
                        {t("button.close")}
                    </OrangeButtonBlank>
                    <LightBrownButtonBlank onClick={() => deleteRecord(id, metadata.backControllerName)}>
                        {t("button.delete")}
                    </LightBrownButtonBlank>
                </ButtonGroup>}
        </div>
    );
};

export default DeleteRecordPopupBody;