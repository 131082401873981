import React from 'react';
import {useLocation} from "react-router-dom";
import PetFormStepOne from "./forms/stepone/PetFormStepOne";
import PetFormStepTwo from "./forms/steptwo/PetFormStepTwo";
import {
    NEW_PET_STEP_FIVE_PATH,
    NEW_PET_STEP_FOUR_PATH,
    NEW_PET_STEP_ONE_PATH,
    NEW_PET_STEP_THREE_PATH,
    NEW_PET_STEP_TWO_PATH
} from "../helpers/petFormConstants";
import PetFormStepThree from "./forms/stepthree/PetFormStepThree";
import PetFormStepFour from "./forms/stepfour/PetFormStepFour";
import PetFormStepFive from "./forms/stepfive/PetFormStepFive";

/**
 * Определяет шаг формы добавления питомца
 * на основании содержимого строки браузера
 */
const PetFormStepsFactory = () => {
    const location = useLocation();
    switch (location.pathname) {
        case NEW_PET_STEP_ONE_PATH:
            return <PetFormStepOne/>;
        case NEW_PET_STEP_TWO_PATH:
            return <PetFormStepTwo/>;
        case NEW_PET_STEP_THREE_PATH:
            return <PetFormStepThree/>;
        case NEW_PET_STEP_FOUR_PATH:
            return <PetFormStepFour/>;
        case NEW_PET_STEP_FIVE_PATH:
            return <PetFormStepFive/>;
        default:
            return <PetFormStepOne/>;
    }
};

export default PetFormStepsFactory;