import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {DISTINCTIVE_FEATURES} from "../../../newpet/helpers/petFormConstants";
import {getActivePetForUpdatePetInfo} from "../../../newpet/helpers/petHelper";
import PartInfoContent from "../../../../../components/common/form/abstract/PartInfoContent";
import UpdatableElementPreview from "../../../../../components/common/UpdatableElementPreview";
import PetForm from "../../../newpet/components/forms/PetForm";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import FormSubmitButton from "../../../../../components/common/form/parts/inputs/FormSubmitButton";
import TextArea from "../../../../../components/common/form/parts/inputs/TextArea";
import FormItem from "../../../../../components/common/form/parts/inputs/wrappers/FormItem";
import {updatePetIdentificationInfo} from "../../../../../app/store/userReducer";
import PetService from "../../../newpet/api/PetService";
import {useTranslation} from "react-i18next";

/**
 * Работа с информацией об отличительных особенностях питомца
 */
const PetDistinctiveFeatures = () => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const activePet = useSelector(state => state.userReducer.activePet);
    const pets = useSelector(state => state.userReducer.pets);
    const token = useSelector(state => state.userReducer.token);
    const [errorMessage, setErrorMessage] = useState("");
    const [distinctiveFeaturesErrorMessage, setDistinctiveFeaturesErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const about = `Необычный окрас, орнамент на шерсти, поведение или привычки.\n
Данная информация нигде не используется и видна только Вам.\n
В дальнейшем она может потребоваться если Ваш питомец потерялся`;
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function clearErrors() {
        setErrorMessage("");
        setDistinctiveFeaturesErrorMessage("");
    }

    function isButtonActive() {
        return activePet.identificationInfo?.distinctiveFeatures !== watch(DISTINCTIVE_FEATURES) &&
            watch(DISTINCTIVE_FEATURES) !== '';
    }

    function init() {
        setValue(DISTINCTIVE_FEATURES, activePet.identificationInfo?.distinctiveFeatures);
    }

    function submitForm(data) {
        if (!isButtonActive()) {
            return;
        }
        clearErrors();
        setIsLoading(true)
        const response = PetService.updatePetDistinctiveFeatures(activePet.id, data.distinctiveFeatures, token);
        response.then((resp) => {
            dispatch(updatePetIdentificationInfo({
                identificationInfo: resp.data.data
            }))
        }, (error) => {
            setDistinctiveFeaturesErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        });
        setIsLoading(false)
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        getActivePetForUpdatePetInfo(activePet.id, params.id, init, pets, setErrorMessage, token, dispatch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PartInfoContent errorMessage={errorMessage}>
            <UpdatableElementPreview title={t('page.pet.distinctiveFeatures')} backTo={`/pets/${params.id}`}
                                     content={about} needReload={true}/>
            <PetForm handleSubmit={handleSubmit} submitForm={submitForm}>
                <FormItem>
                    <TextArea register={register} fieldName={DISTINCTIVE_FEATURES}
                              placeholder={t('page.pet.tellAbout')} hasValue={getValues(DISTINCTIVE_FEATURES) !== ''}/>
                </FormItem>
                <FormError title={distinctiveFeaturesErrorMessage}/>
                <FormSubmitButton title={t('button.save')} isLoading={isLoading} isButtonActive={isButtonActive}
                                  handleSubmit={handleSubmit} submitForm={submitForm}/>
            </PetForm>
        </PartInfoContent>
    );
};

export default PetDistinctiveFeatures;