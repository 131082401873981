import React from 'react';

/**
 * Кнопка Submit на форме
 */
const SubmitButton = ({label, disabledSubmit = false}) => {
    return (
        <button type="submit" disabled={disabledSubmit} className="form__button">{label}</button>
    );
};

export default SubmitButton;