import React from 'react';
import {storeItem} from "../../helper/formHelper";
import FormItem from "./wrappers/FormItem";
import FormError from "./wrappers/FormError";

/**
 * Группа радиокнопок
 * values = {value, title}
 */
const RadioButtons = ({register, fieldName, label, values, toStore = false, hookFormSetValue, errors}) => {

        function doStoreLogic(value) {
            if (toStore) {
                storeItem(fieldName, value);
            }
            hookFormSetValue(fieldName, value);
        }

        return (
            <FormItem>
                <div className="radio-buttons__wrapper">
                    <div className="radio-buttons__label-section">
                <span>
                {label}
                </span>
                    </div>
                    <div className="radio-buttons__buttons-section">

                        {values.map(value => {
                            return (
                                <div className="radio-buttons__buttons-section__item" key={value.value}>
                                    <input type="radio" className="radio-input"
                                           {...register(fieldName)}
                                           id={value.value}
                                           value={value.value}
                                           onChange={(e) => doStoreLogic(e.target.value)}/>
                                    <label className="radio-input__label" htmlFor={value.value}>{value.title}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
            </FormItem>
        );
    }
;

export default RadioButtons;