import React from 'react';
import {useTranslation} from "react-i18next";
import {hasValue} from "../../../../app/helper/commonHelper";
import InformationBlock from "../../../../components/common/ui/text/informationblock/InformationBlock";

/**
 * Блок личной информации в профиле пользователя
 */
const PersonalInfoBlock = ({user}) => {
    const {t} = useTranslation();
    const infoMap = [
        {
            title: t('common.user.name'),
            value: user.firstName,
            hasValue: hasValue(user.firstName),
            onClickPath: '/profile/name'
        },
        {
            title: t('common.user.lastname'),
            value: user.lastName,
            hasValue: hasValue(user.lastName),
            onClickPath: '/profile/lastname'
        },
        {
            title: t('common.user.email'),
            value: user.email,
            hasValue: hasValue(user.email),
            disabled: true
        },
        {
            title: t('common.user.password'),
            value: '******',
            hasValue: true,
            disabled: true
        }
    ];
    return (
        <InformationBlock title={t('common.user.personalData')} infoMap={infoMap}/>
    );
};

export default PersonalInfoBlock;