import React from 'react';

/**
 * Контейнер для элементов хэдера
 */
const HeaderContainer = ({children}) => {
    return (
            <div className="header-container">
                {children}
            </div>
    );
};

export default HeaderContainer;