export function capitalizeFirstLetter(string) {
    return string === undefined || string.size === 0 ? "" : string.charAt(0).toUpperCase() + string.slice(1);
}

export const INPUT_CLASS = 'form__input';
export const CHECKBOX_CLASS = 'checkbox';
export const ERROR_CLASS = '_error';
export const validateNameRegEx = /^[[\p{Letter}\p{Mark}\s-]+$/gu

export function cutLongTexts(isMobile) {
    if (isMobile) {
        // Получаем все блоки div с классом 'item-title'
        let items = document.querySelectorAll('.item-content');

// Проходим по каждому блоку div
        items.forEach(function(item) {
            // Внутри каждого div ищем элементы span
            let spans = item.querySelectorAll('span');
            spans.forEach(function(span) {
                // Обрезаем текст каждого элемента span до 200 символов, если он длиннее
                if (span.textContent.length > 200) {
                    span.textContent = span.textContent.substring(0, 200) + '...';
                }
            });
        });
    }
}

