import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isBurgerActive: false
}

const burgerSlice = createSlice({
    name: 'burgerSlice',
    initialState: initialState,
    reducers: {
        setBurgerActive(state, action) {
            state.isBurgerActive = action.payload;
        }
    }
})

export default burgerSlice.reducer;
export const {setBurgerActive} = burgerSlice.actions;