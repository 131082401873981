import React from 'react';
import {storeItem} from "../../form/helper/formHelper";
import FormError from "../../form/parts/inputs/wrappers/FormError";
import FormItem from "../../form/parts/inputs/wrappers/FormItem";

/**
 * Компонент формы
 * Для удобного выбора одного из двух вариантов
 * first: {value, title}
 *
 * Если опции для выбора слишком длинные, можно воспользоваться полем title и вынести туда вопрос
 */
const CustomSelectorTwoVarsInTable = ({
                                          title = null,
                                          value,
                                          setValue,
                                          first,
                                          second,
                                          fieldName = null,
                                          toStore = false,
                                          register,
                                          hookFormSetValue,
                                          errors
                                      }) => {
    function saveValue(value) {
        setValue(value); // просечиваем значение в управляемый компонент
        hookFormSetValue(fieldName, value); // просечиваем значение в хук формы
        if (toStore) {
            storeItem(fieldName, value); // сохраняем в стор
        }
    }

    return (
        <FormItem>
            <div className="type-selector">
                {title !== null ?
                    <div className="selector-label">
                        <span>{title}</span>
                    </div> : null}
                <div className="selector-options">
                    <div className={first.value === value ?
                        "type-selector__item active" :
                        "type-selector__item"}
                         {...register(fieldName)}
                         onClick={() => saveValue(first.value)}>
                        <span>{first.title}</span>
                    </div>
                    <div className={second.value === value ?
                        "type-selector__item active" :
                        "type-selector__item"}
                         {...register(fieldName)}
                         onClick={() => saveValue(second.value)}>
                        <span>{second.title}</span>
                    </div>
                </div>
            </div>
            {errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>

    );
};

export default CustomSelectorTwoVarsInTable;