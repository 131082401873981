import React from 'react';
import Logo from "../Logo";
import BurgerButton from "../../ui/buttons/BurgerButton";
import MobileMenu from "./menu/MobileMenu";
import HeaderContainer from "../HeaderContainer";

/**
 * Хэдер для мобильной версии
 */
const MobileHeader = () => {
    return (
        <HeaderContainer>
            <Logo/>
            <BurgerButton/>
            <MobileMenu/>
        </HeaderContainer>
    );
};

export default MobileHeader;