import React from 'react';
import {Link} from "react-router-dom";
import {capitalizeFirstLetter} from "../../utils/stringutils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

/**
 * Компонент формирования хлебных крошек
 * Ссылка на главную страницу включена по умолчанию, требуется передать только массив объектов вида
 * [{page, path}]
 * Чтобы обозначить, что страница является текущей, в path следует передать пустую строку
 * */
const Breadcrumbs = ({elements}) => {
    const {t} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);

    function formBreadCrumbs() {
        const rootClasses = ['breadcrumb-item']
        let link = "";
        return elements.map((element, index) => {
                if (element.path === "") {
                    rootClasses.push("active")
                    link = capitalizeFirstLetter(element.page);
                } else {
                    link = <Link to={element.path}>{capitalizeFirstLetter(element.page)}</Link>
                }
                return <li key={index} className={rootClasses.join(" ")}>{link}</li>
            }
        );
    }

    /**
     * Отображать хлебные крошки только для настольной версии
     */
    function renderBreadcrumbs() {
        return isMobile ? <></> : <div className="breadcrumb-wrapper">
            <nav className="breadcrumb">
                <ol className="breadcrumb-items">
                    <li className="breadcrumb-item"><Link to="/">{t("page.main.title")}</Link></li>
                    {formBreadCrumbs()}
                </ol>
            </nav>
        </div>
    }

    return (
        <>
            {renderBreadcrumbs()}
        </>
    );
};

export default Breadcrumbs;