import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./utils/i18next"
import Spinner from "./components/common/Spinner";
import Provider from "react-redux/es/components/Provider";
import store, {persistor} from "./app/store";
import {PersistGate} from "redux-persist/integration/react";
import setupInterceptors from "./utils/interceptors";
// Suspense - механизм динамической загрузки компонентов
// В типичном решении при загрузке страницы React сначала ожидает все данные для первого компонента,
// потом запрашивает данные для следующего. Suspense позволяет одновременно запрашивать данные для всех компонентов
// и отображать компоненты страницы как одновременно, так и независимо друг от друга. Схема:

// Запрос компонента 1
// Запрос компонента 2
// Ожидание…
// Получение компонента 1
// Получение компонента 2
// Отрисовка компонентов


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<Spinner/>}>
        <Provider store={store}>
            <PersistGate loading={<Spinner/>} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </Suspense>
);

setupInterceptors(store);
