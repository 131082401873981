import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {hideModal} from "../../../../app/store/modalReducer";

/**
 * body модального окна: выводит переданный текст закрывает модальное окно через время delay
 */
const AutoCloseableModalWithTitle = ({text, delay}) => {
    const dispatch = useDispatch();

    function closeModal() {
        setTimeout(() => {
            dispatch(hideModal())
            window.location.reload();
        }, delay);
    }


    useEffect(() => {
        closeModal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="success-message">
            <span>{text}</span>
        </div>
    );
};

export default AutoCloseableModalWithTitle;