import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {formCountryDto, formCountrySchema} from "../geoHelper";
import {ACTION_ADD, ACTION_UPDATE, NUMBER, STRING} from "../../../../../components/common/form/helper/formConstants";
import {getLanguageShortName} from "../../../../../utils/langUtils";
import {
    CONTINENTS_METADATA,
    COUNTRY_ALPHA2_CODE,
    COUNTRY_ALPHA3_CODE,
    COUNTRY_CONTINENT_ID,
    COUNTRY_ID,
    COUNTRY_ISO_CODE,
    COUNTRY_METADATA,
    COUNTRY_TRANSLATION_ID,
    COUNTRY_TRANSLATION_TITLE
} from "../geoFormsConstants";
import Form from "../../../../../components/common/form/abstract/Form";
import {getAllRecordsInCurrentTranslation, saveRecord} from "../../../../../service/dictionaryService";
import HiddenInput from "../../../../../components/common/form/parts/inputs/HiddenInput";
import {
    clearSessionDataForEntity,
    getStoredItem,
    isFieldFilled,
    storeItem
} from "../../../../../components/common/form/helper/formHelper";
import TextInputFormItem from "../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import TextInputWithOptionsFormItem
    from "../../../../../components/common/form/parts/inputs/wrappers/TextInputWithOptionsFormItem";
import SubmitSection from "../../../../../components/common/form/parts/controlsection/SubmitSection";
import {isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {EMPTY} from "../../../../../app/const/appConst";

/**
 * Форма добавления страны
 * action = ADD или UPDATE - чтобы разделить логику форм
 */
const CountryForm = ({action, country = null}) => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = useSelector(state => state.userReducer.token);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const language = getLanguageShortName(i18n.language);
    const [continents, setContinents] = useState();

    const {
        register,
        formState: {errors},
        handleSubmit,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(formCountrySchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    function getContinents() {
        setIsLoading(true);
        const response = getAllRecordsInCurrentTranslation(CONTINENTS_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setContinents(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    /**
     * Инит полей формы при добавлении страны
     * просечивает данные из стора для простых полей (не массивов)
     */
    function initAdd() {
        setValue(COUNTRY_ID, getStoredItem(COUNTRY_ID, NUMBER))
        setValue(COUNTRY_ALPHA2_CODE, getStoredItem(COUNTRY_ALPHA2_CODE, STRING))
        setValue(COUNTRY_ALPHA3_CODE, getStoredItem(COUNTRY_ALPHA3_CODE, STRING))
        setValue(COUNTRY_ISO_CODE, getStoredItem(COUNTRY_ISO_CODE, STRING))

        setValue(COUNTRY_TRANSLATION_ID, getStoredItem(COUNTRY_TRANSLATION_ID, NUMBER))
        setValue(COUNTRY_TRANSLATION_TITLE, getStoredItem(COUNTRY_TRANSLATION_TITLE, STRING))

    }

    /**
     * Инит полей формы при обновлении страны
     * просечивает данные из БД
     */
    function initUpdate(country) {
        if (isEmptyOrNull(country)) {
            return;
        }
        setValue(COUNTRY_ID, country.id)
        setValue(COUNTRY_ALPHA2_CODE, country.alpha2Code)
        setValue(COUNTRY_ALPHA3_CODE, country.alpha3Code)
        setValue(COUNTRY_ISO_CODE, country.isoCode)

        setValue(COUNTRY_TRANSLATION_ID, country.translation.id)
        setValue(COUNTRY_TRANSLATION_TITLE, country.translation.title)
        setValue(COUNTRY_CONTINENT_ID, country.continent.id)

        // Для селекта с опциями значение, которое мы будем отправлять на бэк мы берем из стора
        storeItem(COUNTRY_CONTINENT_ID, country.continent.id);
    }

    function submitForm(data) {
        setDisabledSubmit(true);
        setIsLoading(true)
        const dto = formCountryDto(data, language);
        const resp = saveRecord(dto, COUNTRY_METADATA.backControllerName, token)
        resp.then(
            (resp) => {
                clearSessionDataForEntity(COUNTRY_METADATA.fields);
                setError(false);
                setIsLoading(false);
                navigate('/panel/dictionaries/countries');
            },
            (error) => {
                setIsLoading(false)
                setError(true)
                setDisabledSubmit(false);
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            })
    }

    /**
     * Получить первоначальное значение id в селект со списком
     */
    function getContinentInitialId() {
        const storedValue = getStoredItem(COUNTRY_CONTINENT_ID, NUMBER);
        if (storedValue !== 0) {
            return storedValue;
        }

        return ACTION_ADD === action || !country || !country.continent ?
            0 : country.continent.id;
    }

    // просечиваем все поля в форму, когда continents загружены
    useEffect(() => {
        //получаем continents
        getContinents();

        switch (action) {
            case ACTION_ADD :
                return initAdd();
            case ACTION_UPDATE :
                return initUpdate(country);
            default:
                return;
        }
    }, [i18n.language, country]);

    return (
        <Form handleSubmit={handleSubmit} submitForm={submitForm}>
            <HiddenInput fieldName={COUNTRY_ID} register={register}/>
            <HiddenInput fieldName={COUNTRY_TRANSLATION_ID}
                         register={register}/>
            <TextInputFormItem errors={errors} fieldName={COUNTRY_ALPHA2_CODE} placeholder='Alpha2'
                               register={register} hasValue={isFieldFilled(getValues, COUNTRY_ALPHA2_CODE)}
                               tabIndex='1' onBlurFunction={storeItem}/>

            <TextInputFormItem errors={errors} fieldName={COUNTRY_ALPHA3_CODE} placeholder='Alpha3'
                               register={register} hasValue={isFieldFilled(getValues, COUNTRY_ALPHA3_CODE)}
                               tabIndex='2' onBlurFunction={storeItem}/>

            <TextInputFormItem errors={errors} fieldName={COUNTRY_ISO_CODE} placeholder='ISO'
                               register={register} hasValue={isFieldFilled(getValues, COUNTRY_ISO_CODE)}
                               tabIndex='3' onBlurFunction={storeItem}/>

            <TextInputFormItem errors={errors} fieldName={COUNTRY_TRANSLATION_TITLE} placeholder='Название'
                               register={register} hasValue={isFieldFilled(getValues, COUNTRY_TRANSLATION_TITLE)}
                               tabIndex='4' onBlurFunction={storeItem}/>

            <TextInputWithOptionsFormItem fieldName={COUNTRY_CONTINENT_ID} options={continents}
                                          placeholder='Континент'
                                          register={register} tabIndex='5'
                                          initialId={getContinentInitialId()}/>

            <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                           submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
        </Form>
    );
};

export default CountryForm;