import React from 'react';
import {useSelector} from "react-redux";
import TopMenuItems from "./TopMenuItems";
import TopMenuPetBlock from "../petblock/TopMenuPetBlock";

/**
 * Пункты верхнего меню
 * логически разделено на три секции:
 * -- средняя: содержит логотип активного питомца + при наведении раскрывается с дополнительными действиями
 * -- левая: содержит ссылки на разделы сайта (прим. породы)
 * -- правая: предполагалось, что будет тоже, но думаю, что следует удалить
 */
const TopMenu = () => {
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);

    return (
        <nav role="navigation" className="top-menu">
            <div className="top-menu__left">
                <TopMenuItems/>
            </div>
            {isUserAuth ? <TopMenuPetBlock/> : null}
            {isUserAuth ?
                <div className="top-menu__right">
                </div> : null}
        </nav>
    );
};

export default TopMenu;