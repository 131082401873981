import React from 'react';
import {showModal} from "../../../../../app/store/modalReducer";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import ForgotPasswordForm from "../forgotpassword/ForgotPasswordForm";

/**
 * Компонент формы "Забыли пароль?"
 */
const ForgotPasswordFormItem = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function showForgotPasswordForm() {
        dispatch(showModal({
            popup: {
                title: t("form.forgotPassword.title"),
                body: <ForgotPasswordForm/>
            }
        }))
    }

    return (
        <div className="form-item forgot-password-container">
            <span className="link small forgot-password"
                  onClick={(e) => {
                      showForgotPasswordForm(e)
                  }}>{t("form.forgotPassword.link")}</span>
        </div>
    );
};

export default ForgotPasswordFormItem;