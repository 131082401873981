import React from 'react';
import TableNoResults from "../../../../components/common/table/TableNoResults";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import DesktopBreedsTable from "./desktop/DesktopBreedsTable";
import MobileBreedsTable from "./mobile/MobileBreedsTable";

/**
 * Компонент таблицы с информацией о породах животных
 * содержит шапку с обозначением полей и строки со значениями
 **/
const BreedsTable = (props) => {
    const {t} = useTranslation();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    if (props.error !== "") {
        return (<TableNoResults title={props.error}/>);
    }
    if (!props.breedsInfo.length) {
        return (<TableNoResults title={t("page.breeds.noBreedFound")}/>);
    }

    if (isMobile) {
        return <MobileBreedsTable breedsInfo={props.breedsInfo}/>
    }
    return <DesktopBreedsTable breedsInfo={props.breedsInfo}/>

};

export default BreedsTable;