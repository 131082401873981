import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

/**
 * Логотип в шапке
 */
const Logo = () => {
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    const location = useLocation();
    const {t} = useTranslation();
    const rootClasses = ['logo'];

    if (isMobile) {
        rootClasses.push('active');
    }

    return (
        <div className={rootClasses.join((' '))}>
            {location.pathname === '/' ?
                <span>{t("milkins")}</span> :
                <span><Link to="/">{t("milkins")}</Link></span>
            }
        </div>
    );
};

export default Logo;