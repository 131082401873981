import React from 'react';
import {useTranslation} from "react-i18next";

/**
 * Компонент ссылок на ресурсы, из которых была взята информация
 */
const SourceLinks = ({sources}) => {
    const {t} = useTranslation();
    return (
        <div className="article-with-title">
            <div className="article-with-title__title source-header">
                <span>{t("page.breed.section.sources")}</span>
            </div>
            <div className="article-with-title__text sources">
                {sources?.map(source =>
                    <p key={source.borrowedObject}>
                        {source.borrowedObject + ": "}
                        <a key={source.sourceLink} href={source.sourceLink}
                           target="_blank"
                           rel="noreferrer">{source.linkDescription}</a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default SourceLinks;