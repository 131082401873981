import React from 'react';

/**
 * Белая кнопка с оранжевой окантовкой.
 * При наведении становится оранжевой
 */
const OrangeButtonBlank = ({children, ...props}) => {
    return (
        <div {...props} className="orange-btn reverse">
            <span className="orange-btn__label">{children}</span>
        </div>
    );
};

export default OrangeButtonBlank;