import React from 'react';

/**
 * Светло-зеленая кнопка
 */
const DarkGreenButtonByCenter = ({children, ...props}) => {
    return (
        <div {...props} className="dark-green-btn center">
            <span className="dark-green-btn__label">{children}</span>
        </div>
    );
};

export default DarkGreenButtonByCenter;