import React from 'react';
import {NUTRITION_TYPE} from "../../../newpet/helpers/petFormConstants";
import RadioButtons from "../../../../../components/common/form/parts/inputs/RadioButtons";
import {useTranslation} from "react-i18next";

/**
 * Компонент формы "Тип питания питомца"
 */
const PetNutritionTypeFormItem = ({register, hookFormSetValue, errors}) => {
    const {t} = useTranslation();
    const possibleNutritionTypes = [{value: "INDUSTRIAL", title: t('enums.nutritionTypes.industrial')},
        {value: "NATURAL", title: t('enums.nutritionTypes.natural')},
        {value: "MIXED", title: t('enums.nutritionTypes.mixed')}
    ]

    return (
        <RadioButtons register={register} fieldName={NUTRITION_TYPE} label={t('page.pet.newPet.nutritionType')}
                      values={possibleNutritionTypes} toStore={true} hookFormSetValue={hookFormSetValue}
                      errors={errors}/>
    );
};

export default PetNutritionTypeFormItem;