import React from 'react';
import {LeftMenuPanel} from "../../common/menu/menulinks";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

/**
 * Меню админ-панели
 */
const PanelMenu = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    function currentPath(path) {
        return location.pathname === path || location.pathname.startsWith(path)
    }

    return (
        <div className='admin-panel__menu'>
            <div className='admin-panel__menu-items-wrapper'>
                <ul className="admin-panel__menu-items">
                    {LeftMenuPanel.map(page => {
                        return (
                            <li key={page.title} className={currentPath(page.path) ? "_selected" : ""}
                                onClick={() => navigate(`${page.path}`)}>{t(page.title)}</li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default PanelMenu;