import React, {useEffect, useState} from 'react';
import Content from "../../../components/common/Content";
import {useDispatch, useSelector} from "react-redux";
import {hasValue} from "../../../app/helper/commonHelper";
import TableNoResults from "../../../components/common/table/TableNoResults";
import UpdatableElementPreview from "../../../components/common/UpdatableElementPreview";
import CircleImage from "../../../components/common/photo/CircleImage";
import FormError from "../../../components/common/form/parts/inputs/wrappers/FormError";
import Spinner from "../../../components/common/Spinner";
import OrangeButtonByCenter from "../../../components/common/ui/buttons/OrangeButtonByCenter";
import UserService from "../../../service/UserService";
import {setUserPhoto} from "../../../app/store/userReducer";

/**
 * Фото пользователя
 */
const UserPhoto = () => {
    const dispatch = useDispatch();
    const userPhoto = useSelector(state => state.userReducer.photo);
    const token = useSelector(state => state.userReducer.token);
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState(false);
    const [petPhotoError, setPetPhotoError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const onImageChange = (imageList) => {
        setChanged(true);
        setImages(imageList);
    };

    function saveUserPhoto() {
        if (!changed) {
            return;
        }
        setError(false);
        setIsLoading(true);
        const resp = UserService.saveUserPhoto(images.length === 0 ? null : images[0].file, token)
        resp.then(
            (resp) => {
                const data = resp.data.data;
                dispatch(setUserPhoto(data))
                setChanged(false);
                setIsLoading(false);
            },
            (error) => {
                setError(true);
                setPetPhotoError(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message);
                setIsLoading(false);
            })

    }

    function init() {
        setImages([{data_url: `data:image/jpeg;base64,${userPhoto.fileBase64}`, file: null}]);
    }

    function clearErrors() {
        setError(false);
        setErrorMessage("");
        setPetPhotoError("")
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        clearErrors();
        if (hasValue(userPhoto)) {
            init();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Content>
            {errorMessage !== "" ? <TableNoResults title={errorMessage}/> :
                <>
                    <UpdatableElementPreview title="Ваше фото" backTo='/profile'
                                             content="Профиль с фотографией радует глаз. Так вас будут видеть другие пользователи:"/>
                    <CircleImage images={images} maxNumber={1} onChange={onImageChange}/>
                    {error ? <FormError title={petPhotoError}/> : null}
                    {isLoading ?
                        <Spinner/> :
                        <OrangeButtonByCenter isActive={changed} onClick={() => saveUserPhoto()}>
                            Сохранить
                        </OrangeButtonByCenter>
                    }
                </>}
        </Content>
    );
};

export default UserPhoto;