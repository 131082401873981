import React from 'react';
import {useTranslation} from "react-i18next";
import TableTitleOneCol from "../../../../../../components/common/table/simple/title/onecol/TableTitleOneCol";
import SimpleTable from "../../../../../../components/common/table/simple/SimpleTable";
import TableLineOneColLocalized from "../../../../../../components/common/table/simple/line/onecol/TableLineOneColLocalized";
import TableNoResults from "../../../../../../components/common/table/TableNoResults";

/**
 * Таблица с континентами
 */
const ContinentsTable = ({continents, error}) => {
    const {t} = useTranslation();
    if (error !== "") {
        return (<TableNoResults title={error}/>);
    }
    if (!continents.length) {
        return (<TableNoResults title={t("adminPanel.dictionaries.isEmpty")}/>);
    }
    return (
        <SimpleTable>
            <TableTitleOneCol title={
                {
                    first: t('form.common.fields.title'),
                }
            }/>
            {continents.map(continent =>
                <TableLineOneColLocalized key={continent.id} localizedEntity={continent}
                                          isEditable={false} withActions={false} />
            )}
        </SimpleTable>
    );
};

export default ContinentsTable;