import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import IndexContentUnregisterUser from "./IndexContentUnregisterUser";
import DesktopIndexContentAuthUser from "./DesktopIndexContentAuthUser";

/**
 * Главная страница десктопной версии
 */
const DesktopIndexPage = () => {
    const isUserAuth = useSelector(state => state.userReducer.isUserAuth);


    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    return (
        <>
            {isUserAuth ?
                <DesktopIndexContentAuthUser/> :
                <IndexContentUnregisterUser/>
            }
        </>
    );
};


export default DesktopIndexPage;