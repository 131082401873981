import React from 'react';
import SearchInput from "../input/SearchInput";
import {useTranslation} from "react-i18next";
import SearchBlockWithActions from "./SearchBlockWithActions";

/**
 * Фильтр.
 * Состоит из строки поиска и выпадашки с параметрами сортировки
 */
const SimpleFilter = ({sortOptions, filter, setFilter}) => {
    const {t} = useTranslation();

    return (
        <div>
            <SearchInput
                placeholder={t("common.search")}
                value={filter.query}
                onChange={filterValue => setFilter({...filter, query: filterValue})}
            />
            <SearchBlockWithActions sortOptions={sortOptions} filter={filter} setFilter={setFilter}/>
        </div>
    );
};

export default SimpleFilter;