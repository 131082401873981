import React from 'react';
import SortingSelect from "../../../../../components/common/ui/select/SortingSelect";
import {getLocalizedAnimalKinds} from "../../../../../app/const/appConst";
import {PET_ANIMAL_KIND} from "../../../newpet/helpers/petFormConstants";
import FormItem from "../../../../../components/common/form/parts/inputs/wrappers/FormItem";
import {useTranslation} from "react-i18next";

/**
 * Компонент формы "Тип питомца"
 */
const PetAnimalKindFormItem = ({animalKind, disabled, onChangeFunction = null, onBlurFunction = null}) => {
    const {t} = useTranslation();
    return (
        <FormItem>
            <SortingSelect
                value={animalKind}
                defaultValue="это у нас"
                options={getLocalizedAnimalKinds(t)}
                fieldName={PET_ANIMAL_KIND}
                disabled={disabled}
                onBlurFunction={onBlurFunction}
                onChangeFunction={onChangeFunction}
            />
        </FormItem>
    );
};

export default PetAnimalKindFormItem;