import React from 'react';
import {CHIP_NUMBER} from "../../../newpet/helpers/petFormConstants";
import TextInputFormItem from "../../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import {useTranslation} from "react-i18next";

/**
 * Компонент формы "номер чипа питомца"
 */
const PetChipNumberFormItem = ({errors, register, tabIndex, onBlurFunction = null, hasValue = false}) => {
    const {t} = useTranslation();
    return (
        <TextInputFormItem errors={errors} register={register} fieldName={CHIP_NUMBER}
                           placeholder={t('page.pet.chipNumber')} tabIndex={tabIndex} hasValue={hasValue}
                           onBlurFunction={onBlurFunction}/>
    );
};

export default PetChipNumberFormItem;