import React from 'react';

/**
 * Строка таблицы
 * состоит из набора CellsXX. Сумма XX должна быть = 100
 */
const TableLine = ({editable = false, children}) => {
    const customClassName = editable ? 'table-line _editable' : 'table-line';

    return (
        <div className={customClassName}>
            {children}
        </div>
    );
};

export default TableLine;