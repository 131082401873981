import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {makeBlurLogic, movePlaceholderUp} from "../../helper/formHelper";
import {LABEL_CLASSNAME_WITH_VALUE, LABEL_CLASSNAME_WITHOUT_VALUE} from "../../helper/commonFormConstants";

/**
 * Компонент числового инпута
 */
const NumberInput = ({
                         classes, fieldName, register, placeholder, onBlurFunction = null, tabIndex = null,
                         hasValue = false, dispatchFunction = null, min = 0, step = 1
                     }) => {

    const dispatch = useDispatch();
    const [labelClass, setLabelClass] = useState(LABEL_CLASSNAME_WITHOUT_VALUE)

    useEffect(() => {
        setLabelClass(hasValue ?
            LABEL_CLASSNAME_WITH_VALUE : LABEL_CLASSNAME_WITHOUT_VALUE)
    }, [hasValue])

    return (
        <>
            <label htmlFor={fieldName} className={labelClass}>{placeholder}</label>
            <input type="number"
                   className={classes}
                   {...register(fieldName)}
                   step={step}
                   min={min}
                   onBlur={(e) => makeBlurLogic(e.target.value, setLabelClass, fieldName,
                       onBlurFunction, dispatch, dispatchFunction)}
                   onFocus={(e) => movePlaceholderUp(setLabelClass)}
                   tabIndex={tabIndex}
                   autoComplete="off"/>
        </>

    );
};

export default NumberInput;