import React from 'react';

/**
 * Корневой элемент формы
 */
const Form = ({handleSubmit, submitForm, customClassName = '', children}) => {
    return (
        <div className={customClassName === '' ? 'form' : customClassName}>
            <form onSubmit={handleSubmit(submitForm)} className="form__body" noValidate>
                {children}
            </form>
        </div>
    );
};

export default Form;