import React from 'react';
import SmallTitleByCenter from "../../../../ui/text/SmallTitleByCenter";
import FormRangeLine from "./FormRangeLine";
import RangeItem from "../RangeItem";
import NumberInput from "../NumberInput";
import {INPUT_RANGE_CLASS} from "../../../helper/formConstants";
import {ERROR_CLASS} from "../../../../../../utils/stringutils";
import FormError from "./FormError";
import {useTranslation} from "react-i18next";
import {isEmptyOrNull} from "../../../../../../app/helper/commonHelper";

/**
 * Компонент формы "Диапазон"
 */
const RangeFormItem = ({
                           title,
                           fieldNameFrom, fieldNameTo,
                           hasValueFrom, hasValueTo,
                           tabIndexFrom, tabIndexTo,
                           errors, register, onBlurFunction,
                           placeholderFrom = null, placeholderTo = null
                       }) => {
    const {t} = useTranslation();

    function getClassName(fieldName) {
        return errors[fieldName] ? `${INPUT_RANGE_CLASS} ${ERROR_CLASS}` : `${INPUT_RANGE_CLASS}`
    }

    function getPlaceholderFrom() {
        return isEmptyOrNull(placeholderFrom) ? t("form.common.fields.from") : placeholderFrom;
    }

    function getPlaceholderTo() {
        return isEmptyOrNull(placeholderTo) ? t("form.common.fields.to") : placeholderTo;
    }

    return (
        <>
            <SmallTitleByCenter>{title}</SmallTitleByCenter>
            <FormRangeLine>
                <RangeItem>
                    <NumberInput
                        classes={getClassName(fieldNameFrom)}
                        fieldName={fieldNameFrom}
                        register={register}
                        placeholder={getPlaceholderFrom()}
                        hasValue={hasValueFrom}
                        tabIndex={tabIndexFrom}
                        onBlurFunction={onBlurFunction}/>
                </RangeItem>
                <RangeItem>
                    <NumberInput
                        classes={getClassName(fieldNameTo)}
                        fieldName={fieldNameTo}
                        register={register}
                        placeholder={getPlaceholderTo()}
                        hasValue={hasValueTo}
                        tabIndex={tabIndexTo}
                        onBlurFunction={onBlurFunction}/>
                </RangeItem>
                {errors[fieldNameTo] ? <FormError title={errors[fieldNameTo].message}/> : null}
            </FormRangeLine>
        </>
    );
};

export default RangeFormItem;